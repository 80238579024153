import React from "react";
import { Link } from "react-router-dom";

function DoubleCardRigth() {
  return (
    <div className="row">
      <div className="col-lg-4">
        <div className="tips-information-item bg-0a2240">
          <div className="tips-cont-ar">
            <h4>Save Time and Shop Online</h4>
            <p>
              For select Certified Pre-Owned vehicles, you can build your deal
              in a few easy steps and finalize purchase with the dealer.
            </p>
            <Link to="/">Learn more</Link>
          </div>
        </div>
      </div>
      <div className="col-lg-8">
        <div
          className="tips-information-item tips-information-big"
          style={{
            background:
              "url(/images/0-landing-page/XXL-Maintenance-Media-Text-2.jpg)",
          }}
        >
          <div className="tips-cont-ar">
            <h4>Should I buy a new or used car?</h4>
            <p>
              Weigh up the pros and cons before you decide on buying brand-new
              or preowned.
            </p>
            <Link to="/">Learn more</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoubleCardRigth;
