function currencyFormat(num) {
  //return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return new Intl.NumberFormat("pt-PT", {
    style: "currency",
    currency: "MZN",
  })
    .format(num)
    .replace("MZN", "");
}
export default currencyFormat;
