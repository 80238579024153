import React, { useState } from "react";
import DataUtils from "../../services/DataUtils";

/*Single file button component used on dealer registration form*/

function FileUpload({ title, uploaded, selectFile }) {
  // On file select (from the pop up)
  const onFileChange = (event) => {
    const file = event.target.files[0];
    convertSelectedFile(file);
  };

  const [fileName, setFileName] = useState();

  const convertSelectedFile = async (file) => {
    await DataUtils.convertBase64(file, (base64File) => {
      setFileName(file.name);
      selectFile(base64File, file.type, title);
    });
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <div className="attachment-file-item" onClick={handleClick}>
      <input
        type="file"
        onChange={onFileChange}
        style={{ display: "none" }}
        ref={hiddenFileInput}
      />
      <label>{title} </label>
      <div className="attach-file">
        {!uploaded ? (
          <div className="upload-file uploaded">
            <div className="upload-file-img">
              <img src="/images/upload-black.svg" alt="" />
            </div>
            <p>Select File</p>
          </div>
        ) : (
          <div className="upload-file">
            <div className="upload-file-img">
              <img src="/images/upload.svg" alt="" />
            </div>
            <p>{fileName}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default FileUpload;
