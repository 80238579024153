import React from "react";

function SpecItems({ product }) {
  let category = 0;
  if (product.category) {
    category = product.category;
  }
  return (
    <div className="specifications-items">
      <div className="specifications-item">
        <div className="specifications-item-img">
          <img
            src={category === 1 ? "/images/bedroom.svg" : "/images/engine.svg"}
            alt="engine"
          />
        </div>
        <div className="specifications-item-cont">
          <h6>{category === 1 ? "Bedrooms" : "Engine"}</h6>
          <p>Dummy data</p>
        </div>
      </div>

      <div className="specifications-item">
        <div className="specifications-item-img">
          <img
            src={category === 1 ? "/images/bathroom.svg" : "/images/drive.svg"}
            alt="engine"
          />
        </div>
        <div className="specifications-item-cont">
          <h6>{category === 1 ? "Bathrooms" : "Drive"}</h6>
          <p>Dummy data</p>
        </div>
      </div>

      <div className="specifications-item">
        <div className="specifications-item-img">
          <img
            src={category === 1 ? "/images/surface.svg" : "/images/fuel.svg"}
            alt="engine"
          />
        </div>
        <div className="specifications-item-cont">
          <h6>{category === 1 ? "Surface" : "Fuel"}</h6>
          <p>Dummy data</p>
        </div>
      </div>

      {/* <div className="specifications-item">
        <div className="specifications-item-img">
          <img src="/images/power.svg" alt="engine" />
        </div>
        <div className="specifications-item-cont">
          <h6>Power</h6>
          <p>Dummy data</p>
        </div>
      </div>

      <div className="specifications-item">
        <div className="specifications-item-img">
          <img src="/images/seat.svg" alt="engine" />
        </div>
        <div className="specifications-item-cont">
          <h6>Seats</h6>
          <p>Dummy data</p>
        </div>
      </div>

      <div className="specifications-item">
        <div className="specifications-item-img">
          <img src="/images/transmission_old.svg" alt="engine" />
        </div>
        <div className="specifications-item-cont">
          <h6>Transmission</h6>
          <p>Dummy data</p>
        </div>
      </div> */}
    </div>
  );
}

export default SpecItems;
