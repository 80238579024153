import React from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../Constants";
import currencyFormat from "../../services/Utilities";

function CartItemCard({ product }) {
  return (
    <div className="big-select-product-item">
      <div className="big-select-product-item-top-flex">
        <div className="big-select-product-item-top">
          <div className="big-se-pro-item">
            <span></span>
            <p>Select Product</p>
          </div>
          <div className="big-rem-pro-item">
            <span>
              <img src="/images/5section/icons-mail-fill.svg" alt="" />
            </span>
            <span>
              <img src="/images/5section/icons-heart-border.svg" alt="" />
            </span>
            <span>
              <img src="/images/5section/icons-trash.svg" alt="" />
            </span>
          </div>
        </div>
        <div className="count-input space-bottom">
          <Link className="incr-btn" data-action="decrease" to="#">
            –
          </Link>
          <input
            className="quantity"
            type="text"
            name="quantity"
            value="1"
            onChange={() => {}}
          />
          <Link className="incr-btn" data-action="increase" to="#">
            +
          </Link>
        </div>
      </div>
      <div className="big-product-flex">
        <div className="big-product-conten">
          {/* <img
            src="/images/5section/car-logo.png"
            alt="logo"
            className="big-conpany-logo"
          /> */}
          <h5>
            <Link to="#">{product.title}</Link>
          </h5>
          <h6>{currencyFormat(product.price)} MZN</h6>
          <div className="big-product-cat">
            <div className="po-bed-items">
              <div className="po-bed-item">
                <div className="po-bedicon">
                  <img src="/images/engine.svg" alt="engine" />
                </div>
                <div className="po-bedi-cont">
                  <h6>Engine</h6>
                  <p>5 in-line 2.5 Turbo</p>
                </div>
              </div>
              <div className="po-bed-item">
                <div className="po-bedicon">
                  <img src="/images/transmission_new.svg" alt="what" />
                </div>
                <div className="po-bedi-cont">
                  <h6>Transmission</h6>
                  <p>Automatic</p>
                </div>
              </div>
              <div className="po-bed-item">
                <div className="po-bedicon">
                  <img src="/images/fuel.svg" alt="what" />
                </div>
                <div className="po-bedi-cont">
                  <h6>Fuel Type</h6>
                  <p>Gasoline</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="big-product-img">
          <img
            src={`${API_URL}get-product-thumb/${product.id}/${product.id}.png`}
            alt="car"
          />
        </div>
      </div>
    </div>
  );
}

export default CartItemCard;
