import React, { useEffect, useState } from "react";
//import "./Products.css";
import { Link, useParams } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import { Fade } from "react-slideshow-image";
import ProductCard from "./ProductCard";
import ProductListSlide from "./ProductListSlide";
import { API_URL } from "../../Constants";
import CityItem from "./CityItem";
import AgencyCard from "./AgencyCard";
import ProductService from "../../services/ProductService";

function Products() {
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    prevArrow: (
      <div style={{ width: "30px", marginRight: "-30px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#fff"
        >
          <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
        </svg>
      </div>
    ),
    nextArrow: (
      <div style={{ width: "30px", marginLeft: "-30px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#fff"
        >
          <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
        </svg>
      </div>
    ),
  };

  const [byCity, setByCity] = useState([]);
  const [products, setProducts] = useState([]);
  let slug = useParams();

  useEffect(() => {
    const getItemsByCity = async () => {
      ProductService.getHomeItems(slug.category).then((response) => {
        setByCity(response.data);
      });
    };
    const getProducts = async () => {
      ProductService.getHomeProducts(slug.category).then((response) => {
        setProducts(response.data);
      });
    };
    getItemsByCity();
    getProducts();
  }, [slug.category]);

  return (
    <div>
      <div className="slider-area">
        <Fade {...properties}>
          <ProductListSlide
            title={"Find the houses of you dream"}
            description={
              "Pesquise com confiança numa fonte segura de casas para venda"
            }
            className={
              "slider-item " +
              (slug.category === "1" ? "slider-home-1-bg" : "slider-car-1-bg")
            }
            btnOne={"BUY"}
            btnTwo={"AGENCIES"}
          />
          <ProductListSlide
            title={"Explore your next new comfort zone"}
            description={"Have access to multiple payment ways"}
            className={
              "slider-item " +
              (slug.category === "1" ? "slider-home-2-bg" : "slider-car-2-bg")
            }
            btnOne={"BUY"}
            btnTwo={"AGENCIES"}
          />
          <ProductListSlide
            title={"Iploribus Unum"}
            description={
              "Pesquise com confiança numa fonte segura de casas para venda"
            }
            className={
              "slider-item " +
              (slug.category === "1" ? "slider-home-3-bg" : "slider-car-3-bg")
            }
            btnOne={"BUY"}
            btnTwo={"AGENCIES"}
          />
          <ProductListSlide
            title={"Lorem ipsum"}
            description={
              "Pesquise com confiança numa fonte segura de casas para venda"
            }
            className={
              "slider-item " +
              (slug.category === "1" ? "slider-home-4-bg" : "slider-car-4-bg")
            }
            btnOne={"BUY"}
            btnTwo={"AGENCIES"}
          />
        </Fade>
      </div>
      <div className="posted-houses-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <form action="">
                <div className="posted-rearch">
                  <input
                    type="text"
                    placeholder="Search by Location, Types, Names"
                  />
                  <Link to="/">
                    <img src="/images/home.png" alt="" className="home-icon" />
                  </Link>
                  <button type="submit" className="srcicon">
                    <img src="/images/search-big.png" alt="" />
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h2 className="fost-main-heading">Last Posted Houses</h2>
            </div>

            {products.slice(0, 9).map((v) => {
              return (
                <ProductCard
                  key={v.id}
                  productId={v.id}
                  title={v.title}
                  location={v.city.name}
                  price={v.price}
                  age={v.publishDate}
                  url={`${API_URL}get-product-thumb/${v.id}/${v.id}.png`}
                  photos={4}
                />
              );
            })}

            <div className="col-12">
              <div className="more-post">
                <Link to={`/listing/${slug.category}/1`}>
                  <button className="more-post-all bg-0AA0E4">
                    See all Properties
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agencies-registred-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="gencies-registred-warp">
                <h3 className="gencies-registred">
                  Agencies Registred on Marketplace
                </h3>
                <div className="container">
                  <div className="row">
                    <AgencyCard
                      name={"Company XPTO"}
                      services={"muti-services"}
                      posts={56}
                      type={1}
                    />
                    <AgencyCard
                      name={"Company XPTO"}
                      services={"muti-services"}
                      posts={56}
                      type={1}
                    />
                    <AgencyCard
                      name={"Company XPTO"}
                      services={"muti-services"}
                      posts={56}
                      type={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="agencies-city-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="agencies-city">
                <h3 className="gencies-registred">Browse Properties by City</h3>
                <CityItem data={byCity} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
