import { Input, Slider } from "@material-ui/core";
import React, { useState } from "react";
import { toast } from "react-toastify";
import DataUtils from "../../services/DataUtils";
import ProductService from "../../services/ProductService";
import {
  SimulateFinancing,
  SimulateLeasing,
} from "../../services/SimulationUtilities";
import currencyFormat from "../../services/Utilities";

function PaymentSimulation({ price, category, product }) {
  const [earnings, setEarnings] = useState();
  const [months, setMonths] = useState(1);
  const [entry, setEntry] = useState(price * 0.1 || 0);

  const handleMonthSliderChange = (event, newValue) => {
    setMonths(newValue);
  };

  const handleEntrySliderChange = (event, newValue) => {
    setEntry(newValue);
  };

  const simulate = () => {
    return category === 1
      ? SimulateFinancing(price, entry, months)
      : SimulateLeasing(price, entry, months);
  };

  const generateFile = () => {
    if (earnings > 0) {
      let simu = {};
      simu.salary = earnings;
      simu.months = months;
      simu.monthlyPayment = simulate();
      simu.product = product;

      ProductService.getSimulationFile(simu).then(
        (result) => {
          console.log(result);
          if (result.data) {
            DataUtils.openFile(result.data.fileContentType, result.data.file);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      toast.warning("Por favor digite o rendimento líquido");
    }
  };

  return (
    <div className="monthly-Payments-area">
      <h4 className="monthly-Payments-hrading">Monthly Payments (MZN)</h4>
      <h5>Cost: {currencyFormat(price)}</h5>
      <div className="price-range-items">
        <div className="price-range-item range-2">
          <div className="price-range-cont">
            <p>Prazo</p>
            <Input
              value={months}
              onChange={(event) => {
                setMonths(
                  event.target.value === "" ? "" : Number(event.target.value)
                );
              }}
              inputProps={{
                step: 1,
                min: 1,
                max: 280,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </div>
          <Slider
            mix={1}
            max={280}
            value={typeof months === "number" ? months : 0}
            onChange={handleMonthSliderChange}
            aria-labelledby="input-slider"
          />
        </div>

        <div className="price-range-item  range-2">
          <div className="price-range-cont">
            <p>Entrada</p>
            <input
              type="number"
              placeholder="999.999.99,99"
              value={entry}
              onChange={(event) =>
                setEntry(
                  event.target.value === "" ? "" : Number(event.target.value)
                )
              }
            />
          </div>

          <Slider
            min={0}
            max={price}
            value={typeof entry === "number" ? entry : 0}
            onChange={handleEntrySliderChange}
            aria-labelledby="input-slider"
          />
        </div>

        <div className="price-range-item  range-3">
          <div className="price-range-cont">
            <p>Rend. Mensal</p>
            <input
              type="number"
              value={earnings}
              onChange={(event) => {
                setEarnings(
                  event.target.value === "" ? "" : Number(event.target.value)
                );
              }}
            />
          </div>
        </div>
      </div>

      <div className="chart-area">
        <div className="chart-content">
          <h5>Resumo</h5>
          <div className="chart-item">
            <span className="bg-00a1e0"></span>
            <p>
              Prazo de pagamento (Meses) <strong>{months}</strong>
            </p>
          </div>
          <div className="chart-item">
            <span className="bg-ff4949"></span>
            <p>
              Entrada (MZN) <strong>{currencyFormat(entry)}</strong>
            </p>
          </div>
          <div className="chart-item">
            <span className="bg-7c868d"></span>
            <p>
              Rendimento Mensal (MZN){" "}
              <strong>{currencyFormat(earnings || 0)}</strong>
            </p>
          </div>
          <div className="chart-item">
            <span className="bg-0033a1"></span>
            <p>
              Custo (MZN) <strong>{currencyFormat(price)}</strong>
            </p>
          </div>
          <h3 className="chart-total">
            {" "}
            {currencyFormat(simulate())}
            /mês
          </h3>
          <button className="theme-btn" onClick={generateFile}>
            Get Pre-Qualified
          </button>
        </div>

        <div className="main-chart">
          <div
            className="donut donut__custom"
            /* style={{--first: .40; --second: .40; --third: .10; --fourth: .10; --donut-spacing: 0;"}} */
          >
            {/* <div className="donut__slice donut__slice__first"></div>
            <div className="donut__slice donut__slice__second"></div>
            <div className="donut__slice donut__slice__third"></div>
            <div className="donut__slice donut__slice__fourth"></div> */}

            <div className="donut__label">
              <div className="donut__label__heading">
                {earnings
                  ? category === 1
                    ? currencyFormat(
                        (SimulateFinancing(price, entry, months) / earnings) *
                          100
                      )
                    : currencyFormat(
                        (SimulateLeasing(price, entry, months) / earnings) * 100
                      )
                  : 0}
                %
              </div>
              <div className="donut__label__sub">TAXA DE ESFORÇO</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSimulation;
