import React from "react";
import { Link } from "react-router-dom";
import AppsIcon from "@material-ui/icons/Apps";
import ViewListIcon from "@material-ui/icons/ViewList";

function ListingFilter({ handleViewChange, isGrid }) {
  return (
    <div className="col-12">
      <div className="filter-src-warp">
        <div className="filter-src-area">
          <div className="fl-src">
            <p
              style={{
                marginBlockStart: "0px",
                marginBlockEnd: "0px",
                marginInlineStart: "0px",
                marginInlineEnd: "0px",
              }}
            >
              <img src="/images/search.svg" alt="search" />
              12.540 Results
            </p>
          </div>
          <div className="fl-filter">
            <p>
              <img src="/images/filter.svg" alt="FILTERS" />
              FILTERS
            </p>
          </div>
          <div className="fl-room active">
            <p>Brand</p>
            <i className="fas fa-caret-down"></i>
          </div>
          <div className="fl-bathrooms">
            <p>Model</p>
            <i className="fas fa-caret-down"></i>
          </div>
          <div className="fl-others">
            <p>Transmission</p>
            <i className="fas fa-caret-down"></i>
          </div>
          <div className="fl-others">
            <p>Fuel Type</p>
            <i className="fas fa-caret-down"></i>
          </div>
          <div className="fl-others">
            <p>Others</p>
            <i className="fas fa-caret-down"></i>
          </div>

          <div className="fl-price">
            <p>Price</p>
            <div className="fl-price-range">
              <span className="fl-price-range-line"></span>
              <span className="fl-price-range-left">FROM</span>
              <span className="fl-price-range-right">TO</span>
            </div>
          </div>
          <div className="fl-labels">
            <div className="fl-form">
              <p>Label (MZN)</p>
              <input type="text" placeholder="999.999.999" />
            </div>
            <div className="fl-form">
              <p>Label (MZN)</p>
              <input type="text" placeholder="999.999.999" />
            </div>
          </div>
        </div>

        <div className="fl-clear-short">
          <div className="fl-clears">
            <div className="fl-clear">
              <p
                style={{
                  marginBlockStart: "0px",
                  marginBlockEnd: "0px",
                  marginInlineStart: "0px",
                  marginInlineEnd: "0px",
                }}
              >
                applied filter 1 <span>X</span>
              </p>
            </div>
            <div className="fl-clear-all">
              <p>Clear all</p>
            </div>
          </div>
          <div className="fl-clears">
            <div className="fl-short-by">
              <h6>
                Sort by: <span>Low to High</span>{" "}
                <i className="fas fa-caret-down"></i>
              </h6>
            </div>
            <div className="fl-grid-view">
              <Link
                to="#"
                onClick={() => {
                  handleViewChange(true);
                }}
              >
                {isGrid ? (
                  <AppsIcon />
                ) : (
                  <AppsIcon style={{ color: "rgba(124, 134, 141)" }} />
                )}
              </Link>
              <Link
                to="#"
                onClick={() => {
                  handleViewChange(false);
                }}
              >
                {isGrid ? (
                  <ViewListIcon style={{ color: "rgba(124, 134, 141)" }} />
                ) : (
                  <ViewListIcon />
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingFilter;
