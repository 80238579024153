import React, { useEffect, useState } from "react";
import ProductService from "../../services/ProductService";
import Wishlist from "./Wishlist";

function Favorites() {
  const [wishList, setWishList] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      ProductService.getFavoriteList().then((response) => {
        setWishList(response.data);
      });
    };
    getProducts();
  }, []);

  return (
    <div>
      <Wishlist wishList={wishList} />
    </div>
  );
}

export default Favorites;
