import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../Constants";
import { convertDateTimeFromServer, getAge } from "../../services/DateUtils";
import ProductService from "../../services/ProductService";
import currencyFormat from "../../services/Utilities";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

function CategoryOne({
  productId,
  title,
  publishDate,
  city,
  price,
  wishes,
  addRemoveWishlist,
}) {
  const [productCoverImg, setProductCoverImg] = useState();

  const [productAltImages, setProductAltImages] = useState([]);

  useEffect(() => {
    if (productId) {
      setProductCoverImg(
        `${API_URL}get-product-image/${productId}/${productId}.jpeg`
      );
      const getAltImagesIDs = async () => {
        ProductService.getAltImagesId(productId).then((result) => {
          setProductAltImages(result.data);
        });
      };
      getAltImagesIDs();
    }
  }, [productId]);

  return (
    <div className="gallery-2-area">
      <div className="po-home-cont-top" style={{ backgroundImage: "none" }}>
        <div className="po-photo-Velar">
          <h5>{title}</h5>
          <p>{city}</p>
        </div>
        <div className="po-share">
          <div className="cont-date">
            <img src="/images/clock-border.svg" alt="" />
            <span>
              {getAge(convertDateTimeFromServer(publishDate), new Date())}
            </span>
          </div>
          <span>
            <img src="/images/share-color.svg" alt="share" />
          </span>
          <span>
            {/* <img src="/images/heart-color.svg" alt="Love" /> */}
            {wishes ? (
              <Link
                to="#"
                onClick={() => {
                  addRemoveWishlist(productId);
                }}
                className="card_btn_icon_fav"
              >
                <FavoriteIcon />
              </Link>
            ) : (
              <Link
                to="#"
                onClick={() => {
                  addRemoveWishlist(productId);
                }}
              >
                <FavoriteBorderIcon />
              </Link>
            )}
          </span>
        </div>
      </div>

      <div className="left-tab-area">
        <div className="left-tab-items">
          <div className="left-tab-link">
            <div
              className="nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              {productAltImages.slice(0, 5).map((v, k) => {
                return (
                  <Link
                    key={k}
                    to="#"
                    className="nav-link active"
                    id="photo-1-tab"
                    data-toggle="pill"
                    href="#photo-1"
                    role="tab"
                    aria-controls="photo-1"
                    aria-selected="true"
                  >
                    <img
                      height={57}
                      style={{ maxHeight: "57px" }}
                      src={`${API_URL}get-product-alt-image/${v.id}/${v.id}.jpeg`}
                      alt=""
                    />
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="left-tab-view">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="photo-1"
                role="tabpanel"
                aria-labelledby="photo-1-tab"
              >
                <img
                  style={{ objectFit: "cover" }}
                  height={300}
                  src={productCoverImg}
                  alt=""
                />
              </div>

              <div
                className="tab-pane fade"
                id="photo-2"
                role="tabpanel"
                aria-labelledby="photo-2-tab"
              >
                <img
                  src="/images/car/2020-Land_Rover-Range_Rover_Velar%20(6).jpg"
                  alt=""
                />
              </div>

              <div
                className="tab-pane fade"
                id="photo-3"
                role="tabpanel"
                aria-labelledby="photo-3-tab"
              >
                <img
                  src="/images/car/2020-Land_Rover-Range_Rover_Velar%20(7).jpg"
                  alt=""
                />{" "}
              </div>

              <div
                className="tab-pane fade"
                id="photo-4"
                role="tabpanel"
                aria-labelledby="photo-4-tab"
              >
                <img
                  src="/images/car/2020-Land_Rover-Range_Rover_Velar%20(11).jpg"
                  alt=""
                />
              </div>
              <div
                className="tab-pane fade"
                id="photo-5"
                role="tabpanel"
                aria-labelledby="photo-5-tab"
              >
                <img
                  src="/images/car/2020-Land_Rover-Range_Rover_Velar%20(14).jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="po-start-prices">
          {productAltImages.length > 5 ? (
            <div className="tab-more-photos">
              <Link to="/">
                <img
                  className="darken_plus_images"
                  height={57}
                  style={{ maxHeight: "57px" }}
                  src={`${API_URL}get-product-alt-image/${productAltImages[6].id}/${productAltImages[6].id}.jpeg`}
                  alt="car"
                />
              </Link>
              <div className="po-photo-vi">
                <span>
                  <img src="/images/gallery.svg" alt="Photos" />+
                  {productAltImages.length - 4}
                </span>
              </div>
            </div>
          ) : (
            <div className="tab-more-photos"></div>
          )}

          <div className="po-start-price col-00af43">
            <h6>Starting Price (MZN)</h6>
            <p>
              {currencyFormat(price / 18)} <span>/mês</span>{" "}
              <img src="/images/clock-border.svg" alt="" />
            </p>
          </div>
          <div className="po-start-price">
            <h6>
              Total Price (MZN) <img src="/images/not.svg" alt="" />
            </h6>
            <p>{currencyFormat(price)}</p>
          </div>
          <div className="po-start-card">
            <button className="bg-0033a1">
              <i className="fas fa-cart-arrow-down" aria-hidden="true"></i>
              Adicionar ao Carrinho
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryOne;
