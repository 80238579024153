import React, { Suspense, useEffect, useState } from "react";
//import "./App.css";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import "./assets/css/bootstrap.min.css";
import "./i18n";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Register from "./components/user/Register";
import Profile from "./components/user/Profile";
import PasswordReset from "./components/user/PasswordReset";
import Products from "./components/product/Products";
import About from "./components/info/About";
import Policies from "./components/info/Policies";
import Privacy from "./components/info/Privacy";
import Agencies from "./components/product/Agencies";
import ProductListing from "./components/product/ProductListing";
import SignIn from "./components/user/SignIn";
import "react-toastify/dist/ReactToastify.css";
import PasswordResetFinish from "./components/user/PasswordResetFinish";
import DealerRegister from "./components/user/DealerRegister";
import ProductDetail from "./components/product/ProductDetail";
import Favorites from "./components/cart/Favorites";
import firebase from "firebase/app";
import AuthService from "./services/AuthService";
import Cart from "./components/cart/Cart";
import TermsAndCo from "./components/info/TermsAndCo";

function App() {
  const [isAuth, setIsAuth] = useState();

  const loggedUser = localStorage.getItem("user");

  useEffect(() => {
    /* if (loggedUser) {
      setIsAuth(true);
    } */

    AuthService.isAuthenticated().then((result) => {
      setIsAuth(result.data !== undefined && loggedUser);
      if (result.data === undefined) {
        AuthService.logout();
      }
    });

    const configFb = {
      apiKey: "AIzaSyBf2PAcHHCET8f6CGsDmZ0iHIzXVrb9ztQ",
      authDomain: "marketplace-d4612.firebaseapp.com",
      databaseURL: "https://marketplace-d4612.firebaseio.com",
      projectId: "marketplace-d4612",
      storageBucket: "marketplace-d4612.appspot.com",
      messagingSenderId: "841190396135",
      appId: "1:841190396135:web:a9b168ff845e7700821242",
      measurementId: "G-VSB8W3EEZP",
    };
    if (firebase.apps.length === 0) {
      firebase.initializeApp(configFb);
    }
  }, [isAuth, loggedUser]);

  return (
    <div className="app">
      <Router>
        <Suspense fallback={null}>
          <Navbar isAuth={isAuth} setIsAuth={setIsAuth} />

          <Switch>
            <Route
              path="/login"
              component={() => <SignIn handleAuth={setIsAuth} />}
            />
            <Route path="/register/dealer" component={DealerRegister} />
            <Route
              path="/register"
              component={() => <Register handleAuth={setIsAuth} />}
            />
            <Route path="/profile" component={Profile} />
            <Route path="/reset/finish" component={PasswordResetFinish} />
            <Route path="/reset" component={PasswordReset} />
            <Route path="/products/detail/:id" component={ProductDetail} />
            <Route path="/products/:category" component={Products} />
            <Route path="/about" component={About} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={TermsAndCo} />
            <Route path="/policies" component={Policies} />
            <Route path="/agencies" component={Agencies} />
            <Route path="/listing/:category/:page" component={ProductListing} />
            <Route
              path="/listing/:category/:searchQuery/:page"
              component={ProductListing}
            />
            <Route
              path="/listing/:category/:subcategory/:page"
              component={ProductListing}
            />
            <Route
              path="/listing/:category/:subcategory/:searchQuery/:page"
              component={ProductListing}
            />
            <Route path="/favorites" component={Favorites} />
            <Route path="/cart">
              {isAuth ? (
                <Cart />
              ) : (
                <SignIn handleAuth={setIsAuth} redirectUrl={"/cart"} />
              )}
            </Route>

            {/* Pages to be added */}

            {/*
            <Route path="/search/:query" component={Search} />
            <Route path="/agency/detail/:id" component={AgencyPage} />
             */}

            <Route path="/" exact component={Home} />
            <Route path="*">
              <p>404 Not found</p>
            </Route>
          </Switch>
          <Footer />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
