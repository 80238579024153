export const financingConstants = {
  interest: 0.185,
  spread: 0.0325,
};

export const leasingConstants = {
  residual: 0.05,
  primeRate: 0.1317,
  spread: 0,
};

export const SimulateFinancing = (cost, contrib, months) => {
  return (
    PMT(
      ((366 / 360) *
        (financingConstants.interest + financingConstants.spread)) /
        12,
      months,
      cost - contrib,
      0,
      0
    ) * -1
  );
};

export const SimulateLeasing = (cost, contrib, months) => {
  //((CUSTO_TOTAL-CONTRIB_MIN)-((CUSTO_TOTAL-CONTRIB_MIN)*VALOR_RESIDUAL/((1+(PRIME_RATE+SPREAD_INDICATIVO)*(366/360)))^PRAZO))/((1-1/(1+(PRIME_RATE+SPREAD_INDICATIVO)*(366/360)/12)^PRAZO)/((PRIME_RATE+SPREAD_INDICATIVO)*(366/360)/12))
  return (
    (cost -
      contrib -
      ((cost - contrib) * leasingConstants.residual) /
        Math.pow(
          1 +
            (leasingConstants.primeRate + leasingConstants.spread) *
              (366 / 360),
          months
        )) /
    ((1 -
      1 /
        Math.pow(
          1 +
            ((leasingConstants.primeRate + leasingConstants.spread) *
              (366 / 360)) /
              12,
          months
        )) /
      (((leasingConstants.primeRate + leasingConstants.spread) * (366 / 360)) /
        12))
  );
};

function PMT(ir, np, pv, fv, type) {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  var pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0) return -(pv + fv) / np;

  pvif = Math.pow(1 + ir, np);
  pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return pmt;
}
