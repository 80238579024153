import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProductService from "../../services/ProductService";
import { Pagination as MaterialPaging } from "@material-ui/lab";
import ListingFilter from "./ListingFilter";
import ProductCardGrid from "./ProductCardGrid";
import ProductCardList from "./ProductCardList";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ProductListing() {
  let slug = useParams();
  //slug.category

  const [products, setProducts] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [currentPage, setCurrentPage] = useState(slug.page);
  const [gridView, setGridView] = useState(true);
  const [numPages, setNumPages] = useState();
  const itemsPerPage = 20;
  const history = useHistory();

  const handlePageChange = (event, value) => {
    console.log("new page: " + value);
    setCurrentPage(value);
    history.push(`/listing/${slug.category}/${value}`);
    setProducts([]);
    ProductService.searchFilter(
      slug.state || 0,
      slug.category || 0,
      slug.subCategory || 0,
      currentPage,
      itemsPerPage,
      "id,asc"
    ).then((result) => {
      setProducts(result.data);
      setTotalItems(result.headers["x-total-count"]);
      setNumPages(Math.ceil(totalItems / itemsPerPage));
    });
  };

  useEffect(() => {
    const loadAll = () => {
      ProductService.searchFilter(
        slug.state || 0,
        slug.category || 0,
        slug.subCategory || 0,
        currentPage,
        itemsPerPage,
        "id,asc"
      ).then((result) => {
        setProducts(result.data);
        setTotalItems(result.headers["x-total-count"]);
        setNumPages(Math.ceil(totalItems / itemsPerPage));
      });
    };
    loadAll();
  }, [currentPage, slug.category, slug.state, slug.subCategory, totalItems]);

  return (
    <div>
      <div className="acencies-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="home-top-menu care-memu">
                <ul>
                  <li>
                    <Link to="/" className="active">
                      NEW
                    </Link>
                  </li>
                  {slug.category === 2 ? (
                    <li>
                      <Link to="/">USED</Link>
                    </li>
                  ) : (
                    ""
                  )}

                  <li>
                    <Link to="/">DEALERS</Link>
                  </li>
                </ul>
              </div>
            </div>
            <ListingFilter handleViewChange={setGridView} isGrid={gridView} />

            {gridView ? (
              <div className="col-12">
                <div className="box-warp po-car-items">
                  {/* Grid view */}
                  <div className="container">
                    <div className="row">
                      {products.map((v) => {
                        return <ProductCardGrid product={v} key={v.id} />;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="box-warp po-home-items">
                      {products.map((v) => {
                        return <ProductCardList product={v} key={v.id} />;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <Pagination
              total={totalItems}
              itemsPerPage={20}
              page={currentPage}
              range={5}
              onChange={handlePageChange}
            /> */}

            <MaterialPaging
              count={numPages}
              page={Number(currentPage)}
              size="large"
              color="primary"
              showLastButton
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductListing;
