import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "../Constants";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "authenticate", {
        username,
        password,
        accept: "application/json, text/plain, */*",
        "accept-encoding": "gzip, deflate, br",
        "accept-language": "en-US,en;q=0.8",
        "content-type": "application/json;charset=UTF-8",
        "user-agent": "React App",
        Connection: "keep-alive",
        "X-XSRF-TOKEN": "12fde46344e56c8faaf666888c5e24e9c3fafcef",
      })
      .then((response) => {
        console.log(response);
        if (response.data.id_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
          /* getAccount().then((response2) => {
            localStorage.setItem("userData", JSON.stringify(response2.data));
          }); */
        }

        return response.data;
      });
  }

  socialLogin(socialAuthUser) {
    return axios
      .post(
        API_URL + "authenticate-social",
        {
          ...socialAuthUser,
        },
        {
          accept: "application/json, text/plain, */*",
          "accept-encoding": "gzip, deflate, br",
          "accept-language": "en-US,en;q=0.8",
          "content-type": "application/json;charset=UTF-8",
          "user-agent": "React App",
          Connection: "keep-alive",
          "X-XSRF-TOKEN": "12fde46344e56c8faaf666888c5e24e9c3fafcef",
        }
      )
      .then((response) => {
        response.data.id_token = response.data.token;
        delete response.data.token;
        if (response.data.id_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("userData");
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", { username, email, password });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  getAuthenticatedUser() {
    return axios.get(API_URL + "account", { headers: authHeader() });
  }

  isAuthenticated() {
    return axios.get(API_URL + "authenticate", { headers: authHeader() });
  }

  resetPasswordInit(mail) {
    return axios({
      method: "post",
      url: API_URL + "account/reset-password/init",
      data: mail,
      headers: {
        accept: "application/json, text/plain, */*",
        "accept-language": "en-US,en;q=0.8",
        "content-type": "application/json;charset=UTF-8",
        "X-XSRF-TOKEN": "12fde46344e56c8faaf666888c5e24e9c3fafcef",
      },
    });
  }

  resetPasswordFinish(key, newPassword) {
    return axios({
      method: "post",
      url: API_URL + "account/reset-password/finish",
      data: { key, newPassword },
      headers: {
        accept: "application/json, text/plain, */*",
        "accept-language": "en-US,en;q=0.8",
        "content-type": "application/json;charset=UTF-8",
        "X-XSRF-TOKEN": "12fde46344e56c8faaf666888c5e24e9c3fafcef",
      },
    });
  }
}

export default new AuthService();
