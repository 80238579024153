import React from "react";

function Policies() {
  return (
    <div style={{ margin: "100px", backgroundColor: "white", padding: "50px" }}>
      <p>
        <strong>
          Pol&iacute;tica de Publica&ccedil;&atilde;o de Conte&uacute;dos
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        As publica&ccedil;&otilde;es no Marketplace do Standard Bank, S.A
        permitem aos parceiros comunicarem diretamente informa&ccedil;&otilde;es
        relevantes e oportunas aos nossos utilizadores. O Standard Bank, S.A
        escreveu estas pol&iacute;ticas para ajudar a garantir que o
        conte&uacute;do publicado (texto, fotos e v&iacute;deos) crie uma
        experi&ecirc;ncia positiva para os utilizadores.
      </p>
      <p>&nbsp;</p>
      <p>
        Os parceiros do Standard Bank, S.A s&atilde;o respons&aacute;veis por
        garantir que o respetivo conte&uacute;do publicado est&aacute; em
        conformidade com todas as leis e regulamentos aplic&aacute;veis.
      </p>
      <p>
        O Standard Bank, S.A tamb&eacute;m modifica estas pol&iacute;ticas
        ocasionalmente, pelo que as deve consultar regularmente para verificar a
        exist&ecirc;ncia de atualiza&ccedil;&otilde;es. Iremos fornecer uma
        notifica&ccedil;&atilde;o acerca destas altera&ccedil;&otilde;es sempre
        que exigido por lei.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Conte&uacute;do relevante</strong>
      </p>
      <p>
        O conte&uacute;do publicado deve ser relevante para a entidade em nome
        da qual o autor est&aacute; a publicar e ajudar os utilizadores a
        compreender melhor as suas ofertas e servi&ccedil;os. N&atilde;o
        &eacute; permitido conte&uacute;do que seja irrelevante para a entidade
        ou que n&atilde;o tenha uma associa&ccedil;&atilde;o clara &agrave;
        mesma. O Marketplace do Standard Bank, S.A n&atilde;o &eacute; um
        f&oacute;rum para coment&aacute;rios pol&iacute;ticos e sociais gerais,
        nem para discuss&otilde;es pessoais. Todo o conte&uacute;do que
        n&atilde;o cumpra este requisito ser&aacute; removido.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <em>Spam</em>
        </strong>
        <strong> e c&oacute;digo nocivo</strong>
      </p>
      <p>
        O conte&uacute;do publicado neste servi&ccedil;o n&atilde;o deve induzir
        os utilizadores ao erro. Deve ser verdadeiro e honesto acerca das
        informa&ccedil;&otilde;es apresentadas.
      </p>
      <p>Seguem-se exemplos de conte&uacute;do n&atilde;o permitido:</p>
      <ul>
        <li>
          Utiliza&ccedil;&atilde;o de caracteres apenas para fins
          publicit&aacute;rios, texto incompreens&iacute;vel ou conte&uacute;do
          autom&aacute;tico ou perturbante (como imagens/v&iacute;deos de
          m&aacute; qualidade ou desfocados, ou conte&uacute;do
          irreconhec&iacute;vel);
        </li>
        <li>
          Imagens, v&iacute;deos ou <em>links</em> que distraiam a
          aten&ccedil;&atilde;o do utilizador de forma negativa;
        </li>
        <li>
          <em>Links</em> para programas maliciosos, v&iacute;rus ou outro
          software nocivo;
        </li>
        <li>
          <em>Links</em> para p&aacute;ginas irrelevantes para a entidade; e
        </li>
        <li>Esquemas de</li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <strong>Conte&uacute;do impr&oacute;prio e ofensivo</strong>
      </p>
      <p>Este servi&ccedil;o n&atilde;o permite:</p>
      <ul>
        <li>
          Conte&uacute;do de ass&eacute;dio, <em>bullying</em> ou incitamento ao
          &oacute;dio. O conte&uacute;do publicado n&atilde;o pode promover o
          &oacute;dio ou incitar &agrave; viol&ecirc;ncia contra
          indiv&iacute;duos ou grupos com base na origem &eacute;tnica,
          religi&atilde;o, defici&ecirc;ncia, sexo, idade,
          situa&ccedil;&atilde;o militar, orienta&ccedil;&atilde;o ou identidade
          sexual. O texto, as imagens, os v&iacute;deos ou os <em>links</em>{" "}
          n&atilde;o podem incluir insultos nem ep&iacute;tetos depreciativos
          para grupos protegidos. O conte&uacute;do n&atilde;o pode ser
          utilizado para assediar nem intimidar indiv&iacute;duos, incluindo
          amea&ccedil;as f&iacute;sicas directas ou exposi&ccedil;&atilde;o de
          informa&ccedil;&otilde;es privadas que possam ser utilizadas para
          levar a cabo amea&ccedil;as impl&iacute;citas. Conte&uacute;do
          depreciativo acerca de uma pessoa, local ou algo que deixaria o leitor
          ou o ouvinte incomodado ou irritado.
        </li>
        <li>
          Conte&uacute;do que contenha linguagem ou gestos obscenos, profanos ou
          ofensivos.
        </li>
        <li>
          Conte&uacute;do que promova ou incite &agrave; viol&ecirc;ncia, ou que
          represente de forma expl&iacute;cita les&otilde;es, abuso de seres
          humanos ou animais, ou actos violentos. Al&eacute;m disso, n&atilde;o
          &eacute; permitido conte&uacute;do suscept&iacute;vel de chocar ou
          causar repulsa.
        </li>
        <li>
          Conte&uacute;do com conota&ccedil;&atilde;o sexual ou sexualmente
          expl&iacute;cito: As imagens ou os v&iacute;deos publicados neste
          servi&ccedil;o n&atilde;o devem incluir nudez nem actos sexuais. O
          conte&uacute;do n&atilde;o pode incluir linguagem obscena,
          g&iacute;ria que seja sexualmente expl&iacute;cita e ofensiva, termos
          que sejam sinais comuns de pedofilia, conte&uacute;do que promova
          pedofilia e viol&ecirc;ncia sexual ou conte&uacute;do que promova
          servi&ccedil;os de acompanhantes ou outros servi&ccedil;os que possam
          ser interpretados como realiza&ccedil;&atilde;o de actos sexuais em
          troca de compensa&ccedil;&atilde;o. N&atilde;o s&atilde;o permitidos{" "}
          <em>links</em> para conte&uacute;do para adultos.
        </li>
        <li>
          Conte&uacute;do terrorista: O Standard Bank n&atilde;o permite que
          organiza&ccedil;&otilde;es terroristas utilizem este servi&ccedil;o
          para qualquer finalidade, incluindo recrutamento. Al&eacute;m disso,
          proibimos estritamente conte&uacute;do relacionado com terrorismo, tal
          como conte&uacute;do que promova actos terroristas, incite &agrave;
          viol&ecirc;ncia ou celebre ataques terroristas. Se publicar
          conte&uacute;dos relacionados com terrorismo para um objetivo
          educativo, documental, cient&iacute;fico ou art&iacute;stico, tenha o
          cuidado de fornecer informa&ccedil;&otilde;es suficientes para que os
          visitantes compreendam o contexto.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <strong>Privacidade</strong>
      </p>
      <p>
        A privacidade dos utilizadores &eacute; extremamente importante para o
        Standard Bank, S.A. Embora o Marketplace incentive a
        contribui&ccedil;&atilde;o de conte&uacute;do que ilustre as
        prefer&ecirc;ncias do utilizador, &eacute; essencial que esta
        contribui&ccedil;&atilde;o respeite as prefer&ecirc;ncias de privacidade
        das outras pessoas.
      </p>
      <p>
        Nota: o Standard Bank, S.A n&atilde;o permite conte&uacute;do que tenha
        informa&ccedil;&otilde;es privadas ou confidenciais, como
        informa&ccedil;&otilde;es financeiras pessoais, documentos de
        identifica&ccedil;&atilde;o emitidos por uma entidade governamental,
        informa&ccedil;&otilde;es de contacto associadas a um nome, registos
        confidenciais, imagens, transcri&ccedil;&otilde;es ou <em>links</em>{" "}
        sens&iacute;veis que contenham informa&ccedil;&otilde;es pessoais.
        Tamb&eacute;m n&atilde;o permitimos a solicita&ccedil;&atilde;o das
        informa&ccedil;&otilde;es acima indicadas, seja por meio de
        publica&ccedil;&otilde;es locais ou descri&ccedil;&otilde;es da
        entidade, com excep&ccedil;&atilde;o do pr&oacute;prio nome, email e
        n&uacute;mero de telefone do utilizador.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Directrizes para fotos e v&iacute;deos</strong>
      </p>
      <ul>
        <li>
          N&atilde;o deve publicar fotos ou v&iacute;deos tirados em zonas onde
          os mesmos s&atilde;o proibidos. As fotos e os v&iacute;deos de locais
          privados ou inacess&iacute;veis ao p&uacute;blico n&atilde;o devem ser
          publicados sem a autoriza&ccedil;&atilde;o do respectivo
          propriet&aacute;rio.
        </li>
        <li>
          N&atilde;o publique fotos ou v&iacute;deos em que as pessoas sejam
          identific&aacute;veis sem a respectiva autoriza&ccedil;&atilde;o. Esta
          regra &eacute; particularmente importante em locais com acesso
          restrito, onde as pessoas podem n&atilde;o aceitar a
          publica&ccedil;&atilde;o de imagens.
        </li>
        <li>
          Apenas &eacute; permitida a representa&ccedil;&atilde;o fortuita de
          bens regulamentados.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <strong>Explora&ccedil;&atilde;o infantil</strong>
      </p>
      <p>
        N&atilde;o carregue ou partilhe conte&uacute;dos que constituam
        explora&ccedil;&atilde;o ou abuso de menores. Tal abrange todas as
        imagens de abuso sexual infantil (incluindo desenhos) e todos os
        conte&uacute;dos que apresentem crian&ccedil;as de forma sexual. Iremos
        remover esse conte&uacute;do e tomar as medidas necess&aacute;rias, que
        podem incluir desactivar contas e enviar uma den&uacute;ncia para as
        autoridades competentes.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          Representa&ccedil;&atilde;o fraudulenta e afirma&ccedil;&otilde;es
          enganadoras
        </strong>
      </p>
      <p>
        N&atilde;o se fa&ccedil;a passar por uma organiza&ccedil;&atilde;o ou um
        indiv&iacute;duo. N&atilde;o fa&ccedil;a declara&ccedil;&otilde;es
        falsas acerca da sua identidade ou qualifica&ccedil;&otilde;es, como
        alegar falsamente ser um representante autorizado de uma entidade.
      </p>
      <p>
        N&atilde;o forne&ccedil;a informa&ccedil;&otilde;es incorrectas que
        omitam ou dissimulem a forma de factura&ccedil;&atilde;o ou
        cobran&ccedil;a ao utilizador.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Conte&uacute;do proibido</strong>
      </p>
      <p>
        Os produtos enviados atrav&eacute;s do editor de produtos t&ecirc;m de
        cumprir a Pol&iacute;tica de Conte&uacute;dos de
        Publica&ccedil;&otilde;es. N&atilde;o permitimos conte&uacute;do
        n&atilde;o relacionado com a oferta de ve&iacute;culos, equipamentos,
        propriedades e servi&ccedil;os associados.
      </p>
      <p>
        N&atilde;o &eacute; permitido conte&uacute;do relacionado com a venda de
        actividades, produtos ou servi&ccedil;os perigosos e ilegais que causem
        preju&iacute;zos, danos ou les&otilde;es.
      </p>
    </div>
  );
}

export default Policies;
