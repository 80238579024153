import React from "react";
import { Link } from "react-router-dom";
import ProductDetailGallery from "./ProductDetailGallery";

function CategoryThree() {
  return (
    <div>
      <div className="colors-area">
        <div className="color-items owl-carousel">
          <div className="color-item">
            <img src="/images/car/1.png" alt="2020" />
          </div>
          <div className="color-item">
            <img src="/images/car/2.png" alt="2020" />
          </div>
          <div className="color-item">
            <img src="/images/car/3.png" alt="2020" />
          </div>
          <div className="color-item">
            <img src="/images/car/4.png" alt="2020" />
          </div>
          <div className="color-item">
            <img src="/images/car/5.png" alt="2020" />
          </div>
        </div>
        <div className="available-color">
          <h3 className="avilable-color-heading">Available Colors</h3>
          <div className="available-color-flex">
            <h6>Exterior</h6>
            <ul>
              <li className="bg-0b50e6">
                <Link to="/"></Link>
              </li>
              <li className="bg-f3bd6c">
                <Link to="/"></Link>
              </li>
              <li className="bg-ff4949">
                <Link to="/"></Link>
              </li>
              <li className="bg-4f747a">
                <Link to="/"></Link>
              </li>
              <li className="bg-fff active">
                <Link to="/"></Link>
              </li>
              <li className="bg-000">
                <Link to="/"></Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ProductDetailGallery />
    </div>
  );
}

export default CategoryThree;
