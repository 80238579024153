import React, { useEffect, useState } from "react";
import ProductService from "../../services/ProductService";
import CartItemCard from "./CartItemCard";
import ProfileSideBar from "./ProfileSideBar";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";
import currencyFormat from "../../services/Utilities";

function Cart() {
  const [cartItems, setCartItems] = useState([]);
  const [selectedItems] = useState(0);

  useEffect(() => {
    const getProducts = async () => {
      ProductService.getCartList().then((response) => {
        console.log(response);
        setCartItems(response.data);
      });
    };
    getProducts();
  }, []);

  return (
    <div className="page-wrap">
      <ProfileSideBar />

      <div className="big-page-area">
        <div className="page-heading-top">
          <h5>Cart</h5>
          <ul className="page-link-big">
            <li>
              <Link to="#">
                Dashboard <ChevronRightIcon />{" "}
              </Link>
            </li>
            <li>
              <Link to="#">
                Cart <ChevronRightIcon />
              </Link>
            </li>
            <li>
              <span>My Cart</span>
            </li>
          </ul>
        </div>
        <div className="big-page-flex">
          <div className="big-select-product-items">
            <div className="bg-select-all-product">
              <h5>{selectedItems} Selected</h5>
              <div className="bg-select-prod-items">
                <div className="bg-select-prod-item">
                  <div className="all-select">
                    <span></span>
                    <p>Select All</p>
                  </div>
                </div>
                <div className="bg-select-prod-item">
                  <div
                    className="all-select-delete"
                    data-toggle="modal"
                    data-target="#confirmation-dialog"
                  >
                    <img src="/images/5section/icons-trash-red.svg" alt="" />
                    <p>Remove from Cart</p>
                  </div>
                </div>
              </div>
            </div>

            {cartItems.map((v, k) => {
              return <CartItemCard product={v.product} key={k} />;
            })}
          </div>
          <div className="big-summary-area">
            <h5 className="summary-headin-top">Summary</h5>
            <div className="big-summary-items">
              <div className="big-summary-item">
                <p>
                  Cost of products <strong>{currencyFormat(0)} MZN</strong>
                </p>
                <p>
                  Fee, Shipping <strong>{currencyFormat(0)} MZN</strong>
                </p>
                <p className="big-summary-total">
                  Total Price<strong>{currencyFormat(0)} MZN</strong>
                </p>
                <button className="bg-00a860">
                  Proceed to Checkout ({selectedItems}){" "}
                  <i className="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>

          {/* <div className="wishlist-positio">
            <div className="wishlist-close">
              <img src="/images/5section/icons-close-circle-fill.svg" alt="" />
            </div>
            <h4>
              <img src="/images/5section/icons-check-circle.svg" alt="" />
              Item added to Wishlist
            </h4>
            <Link to="#" className="bg-00a860">
              View Wishlist
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Cart;
