import React from "react";
import { Link } from "react-router-dom";

function DoubleCardLeft({
  titleLeft,
  titleRight,
  descriptionLeft,
  descriptionRight,
  backgroundLeft,
  backgroundRight,
  linkLeft,
  linkRight,
  linkLeftTitle,
  linkRightTile,
}) {
  return (
    <div className="row">
      <div className="col-lg-8">
        <div
          className="tips-information-item tips-information-big"
          style={{
            background: backgroundLeft,
          }}
        >
          <div className="tips-cont-ar">
            <h4>{titleLeft}</h4>
            <p>{descriptionLeft}</p>
            <Link to={linkLeft}>{linkLeftTitle}</Link>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className={backgroundRight}>
          <div className="tips-cont-ar">
            <h4>{titleRight}</h4>
            <p>{descriptionRight}</p>
            <Link to={linkRight}>{linkRightTile}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoubleCardLeft;
