import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import DealerRegisterData from "./DealerRegisterData";
import UserService from "../../services/user.service";
import { toast } from "react-toastify";

function DealerRegister() {
  const [showNext, setShowNext] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});

  password.current = watch("password", "");

  const [userData, setUserData] = useState({});

  const onSubmit = (data) => {
    const user = {};
    user.email = data.email;
    user.password = data.password;
    user.phone = data.phone;
    user.login = data.email;
    user.langKey = "pt-pt";
    user.authorities = ["ROLE_USER", "ROLE_COMPANY_MANAGER"];
    setUserData(user);
    UserService.sellerRegister(user)
      .then((result) => {
        if (result.status === 201) {
          setShowNext(true);
          toast.success("Account created. Please fill the details");
        }
      })
      .catch(function (error) {
        console.log({ ...error });
        if (error.response.data.errorKey === "userexists") {
          toast.error("Email already in use");
        }
      });
  };

  return (
    <div>
      {showNext ? (
        <DealerRegisterData userData={userData} setShowNext={setShowNext} />
      ) : (
        <div className="sign-up-area dealers-signup">
          <div className="wid-800">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "50%" }}
              >
                <span className="progress-ps">50%</span>
              </div>
            </div>
            <h2 className="sign-heading">Sign Up DEALERS</h2>
            <div className="sing-up-flex">
              <div className="sing-up-left">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="input-box">
                    <label htmlFor="">Email *</label>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      className="email"
                      name="email"
                      ref={register({ required: "Please enter a valid mail" })}
                    />
                    {errors.email && (
                      <div className="valid-email">
                        <p>{errors.email.message}</p>
                      </div>
                    )}
                  </div>
                  <div className="input-box">
                    <label htmlFor="">Password *</label>
                    <input
                      type="password"
                      placeholder="Create a Password"
                      className="password"
                      name="password"
                      ref={register({
                        required: "You must specify a password",
                        minLength: {
                          value: 6,
                          message: "Password must have at least 6 characters",
                        },
                      })}
                    />
                    {errors.password && (
                      <div className="valid-email">
                        <p>{errors.password.message}</p>
                      </div>
                    )}
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      className="password"
                      name="passwordConfirm"
                      ref={register({
                        validate: (value) =>
                          value === password.current ||
                          "The passwords do not match",
                      })}
                    />
                    {errors.passwordConfirm && (
                      <div className="valid-email">
                        <p>{errors.passwordConfirm.message}</p>
                      </div>
                    )}
                  </div>

                  <div className="input-box">
                    <label htmlFor="">Phone *</label>
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      className="phone"
                      placeholder="Phone Number"
                      ref={register({
                        required: "You must specify a phone",
                        minLength: {
                          value: 9,
                          message: "Phone must have at least 9 characters",
                        },
                      })}
                    />
                    {errors.phone && (
                      <div className="valid-email">
                        <p>{errors.phone.message}</p>
                      </div>
                    )}
                  </div>

                  <div className="input-box">
                    <div className="check-box">
                      <input
                        type="checkbox"
                        id="checkbox3"
                        ref={register({
                          required: "You must agree to the terms of service",
                        })}
                        name="terms"
                        onChange={() => {}}
                      />
                      <label htmlFor="checkbox3">
                        I agree to the Marketplace{" "}
                        <Link to="/terms">Terms of Use</Link> and acknowledge
                        the <Link to="/policies">Publication Policy</Link>
                      </label>
                      <p></p>
                      {errors.terms && (
                        <h6 className="errer-fiscal">{errors.terms.message}</h6>
                      )}
                    </div>
                    <p></p>
                    <h6 className="error-all">
                      * All fields with (asterichs) are mandatory
                    </h6>
                  </div>
                  <div className="input-box">
                    <div className="recaptcha" id="g-recaptcha2"></div>
                  </div>
                  <div className="input-box">
                    <input
                      type="submit"
                      value="Sign Up"
                      className="theme-btn col-000"
                    />
                  </div>
                </form>
              </div>
              <div className="sing-up-right">
                <h6>Et quidem faciunt ut dolore</h6>
                {/* <div className="face-book-google">
                  <Link to="/" className="face-book">
                    <img src="/images/facebook-og.svg" alt="" />
                    <span>Continue with Facebook</span>
                  </Link>
                  <Link to="/" className="continue-google">
                    <img src="/images/google-colors.svg" alt="" />
                    <span>Continue with Google</span>
                  </Link>
                </div> */}
                <p>
                  By continuing, I agree to the Marketplace{" "}
                  <Link to="/terms">Terms of Use</Link> and acknowledge the{" "}
                  <Link to="/policies">Publication Policy</Link>. The MKB group
                  may send me marketing emails. I can opt-out at any time
                  through my settings.
                </p>
                <div className="already-account">
                  <p>
                    Already have an account?<Link to="/">Sign In</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pagination-area">
            <ul>
              <li className="active"></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default DealerRegister;
