import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import firebase from "firebase/app";
import "firebase/auth";

function SignIn({ handleAuth, redirectUrl }) {
  const { register, handleSubmit, errors } = useForm();
  const [showLoginError, setShowLoginError] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const signInGoogle = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    socialSignIn(provider);
  };

  const signInFacebook = () => {
    var provider = new firebase.auth.FacebookAuthProvider();
    socialSignIn(provider);
  };

  const socialSignIn = (provider) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //var token = result.credential.accessToken;
        // The signed-in user info.
        //var user = result.user;

        let socialAuthUser = {};
        socialAuthUser.email = result.user.email;
        socialAuthUser.displayName = result.user.displayName;
        socialAuthUser.profilePhotoUrl = result.user.photoURL;

        AuthService.socialLogin(socialAuthUser)
          .then(
            (result) => {
              toast.success("Login successful");
              if (redirectUrl) {
                history.push(redirectUrl);
              } else {
                history.push("/profile");
              }

              handleAuth(true);
            },
            (error) => {
              setShowLoginError(true);
            }
          )
          .catch((error) => {
            //console.log("ERROR : " + error);
          });
      })
      .catch(function (error) {
        // Handle Errors here.
        //var errorCode = error.code;
        //var errorMessage = error.message;
        // The email of the user's account used.
        //var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        //var credential = error.credential;
        // ...
        toast.error("Login failed");
      });
  };

  const onSubmit = (data) => {
    AuthService.login(data.email, data.password)
      .then(
        () => {
          toast.success("Login successful");
          history.push("/profile");
          handleAuth(true);
        },
        (error) => {
          setShowLoginError(true);
        }
      )
      .catch((error) => {
        console.log("ERROR : " + error);
      });
  };

  return (
    <div>
      <div className="sign-up-area user-signup">
        <div className="wid-800">
          <h2 className="sign-heading">{t("login.authenticate")}</h2>
          <div className="sing-up-flex">
            <div className="sing-up-left">
              {showLoginError ? (
                <div className="valid-email">
                  <p>{t("login.error")}</p>
                </div>
              ) : (
                ""
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-box">
                  <label htmlFor="">{t("login.email")} </label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="email"
                    name="email"
                    ref={register({
                      required: "You must specify a valid email",
                    })}
                  />
                  {errors.email && (
                    <div className="valid-email">
                      <p>{errors.email.message}</p>
                    </div>
                  )}
                </div>
                <div className="input-box">
                  <label htmlFor="">{t("login.password")} </label>
                  <input
                    type="password"
                    placeholder="Senha"
                    className="password"
                    name="password"
                    ref={register({
                      required: "Please enter a password",
                      minLength: 4,
                    })}
                  />
                  {errors.password && (
                    <div className="valid-email">
                      <p>{errors.password.message}</p>
                    </div>
                  )}
                </div>

                <div className="input-box">
                  <div className="check-box">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      checked={true}
                      onChange={() => {}}
                    />
                    <label htmlFor="checkbox1">Manter-me autenticado</label>
                  </div>
                </div>
                <h6>
                  {t("login.cantLogin")} &nbsp;
                  <Link to="/reset">{t("login.recover")}</Link>
                </h6>
                <div className="input-box">
                  <input
                    type="submit"
                    value="Entrar"
                    className="theme-btn col-000"
                  />
                </div>
              </form>
            </div>
            <div className="sing-up-right">
              <h6>{t("login.social")}</h6>
              <div className="face-book-google">
                <Link to="#" onClick={signInFacebook} className="face-book">
                  <img src="/images/facebook-og.svg" alt="" />
                  <span>{t("login.facebook")}</span>
                </Link>
                <Link to="#" onClick={signInGoogle} className="continue-google">
                  <img src="/images/google-colors.svg" alt="" />
                  <span>{t("login.google")}</span>
                </Link>
              </div>
              <p>
                By continuing, I agree to the MKB Terms of Use and acknowledge
                the MKB Privacy Policy. The MKB group may send me marketing
                emails. I can opt-out at any time through my settings.
              </p>
              <div className="already-account">
                <p>
                  {t("login.noAccount")}{" "}
                  <Link to="/register">{t("login.register")}</Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="pagination-area">
          <ul>
            <li className="active"></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
