import { Slide } from "react-slideshow-image";
import React from "react";
import ProductCard from "./ProductCard";
import "react-slideshow-image/dist/styles.css";

function SimilarProducts() {
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
  };
  return (
    <div className="similar-cars-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="similar-car-cont">
              <h4>Similar cars</h4>
              <p>
                At vero eos censes aut dolores eos, qui studiose antiqua
                persequeris, claris et. Alii autem, quibus ego assentior, cum a
                philosophis compluribus permulta dicantur, cur verear. Quid ex
                ea voluptate velit esse, quam ostendis sed et voluptatem ut
                dolore.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="similar-cars container">
        <Slide {...properties}>
          <div className="slide-item">
            <div className="row">
              <ProductCard
                title={"Apartamento T3 no Condomínio Tulipas"}
                location={"Maputo"}
                price={2000000}
                age={"5"}
                wishes={false}
                url={`/images/0-landing-page/004.jpg`}
                photos={4}
              />
              <ProductCard
                title={"Apartamento T3 no Condomínio Tulipas"}
                location={"Maputo"}
                price={2000000}
                age={"5"}
                wishes={false}
                url={`/images/0-landing-page/004.jpg`}
                photos={4}
              />
              <ProductCard
                title={"Apartamento T3 no Condomínio Tulipas"}
                location={"Maputo"}
                price={2000000}
                age={"5"}
                wishes={false}
                url={`/images/0-landing-page/004.jpg`}
                photos={4}
              />
            </div>
          </div>
          <div className="slide-item">
            <div className="row">
              <ProductCard
                title={"Apartamento T3 no Condomínio Tulipas"}
                location={"Maputo"}
                price={2000000}
                age={"5"}
                wishes={false}
                url={`/images/0-landing-page/004.jpg`}
                photos={4}
              />
              <ProductCard
                title={"Apartamento T3 no Condomínio Tulipas"}
                location={"Maputo"}
                price={2000000}
                age={"5"}
                wishes={false}
                url={`/images/0-landing-page/004.jpg`}
                photos={4}
              />
              <ProductCard
                title={"Apartamento T3 no Condomínio Tulipas"}
                location={"Maputo"}
                price={2000000}
                age={"5"}
                wishes={false}
                url={`/images/0-landing-page/004.jpg`}
                photos={4}
              />
            </div>
          </div>
        </Slide>
      </div>
    </div>
  );
}

export default SimilarProducts;
