import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { ToastContainer, toast } from "react-toastify";

function PasswordResetFinish() {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const resetKey = query.get("key");
  const goToLogin = () => {};
  const [showSuccess, setShowSuccess] = useState(false);

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    AuthService.resetPasswordFinish(resetKey, data.password).then(
      (response) => {
        setShowSuccess(true);
        toast.success("Your password was set successfully, please login", {
          autoClose: 8000,
          hideProgressBar: false,
          onClick: goToLogin,
        });
      },
      (error) => {
        toast.error("There was an error resetting your password");
      }
    );
  };

  return (
    <div className="sign-up-area new-password">
      <div className="wid-410">
        {showSuccess ? (
          <div class="pd-area">
            <div class="profile-img">
              <img src="/images/car/boris-m-_CiyeM2kvqs-unsplash.jpg" alt="" />
            </div>

            <h3 className="sign-heading">Success,</h3>
            <h6>Your password was reset successfully </h6>
            <h6>
              Click <Link to="/login">here</Link> to login
            </h6>
          </div>
        ) : (
          <div className="pd-area">
            <div className="profile-img">
              <img src="/images/car/boris-m-_CiyeM2kvqs-unsplash.jpg" alt="" />
            </div>
            <h3 className="sign-heading">Choose a new password</h3>
            <h6>A secure words that you’ll remember</h6>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="input-box">
                <input
                  type="password"
                  placeholder="Enter your password"
                  className="password"
                  name="password"
                  ref={register({
                    required: true,
                    maxLength: 100,
                    minLength: 4,
                  })}
                />
              </div>
              {errors.password ? (
                <div className="valid-email">
                  <p>Please enter a valid password</p>
                </div>
              ) : (
                ""
              )}
              <div className="input-box">
                <input type="submit" value="Continue" className="theme-btn" />
              </div>
            </form>
            <Link to="/login" className="return-log">
              Still having trouble logging in?
            </Link>
          </div>
        )}
      </div>

      <div className="pagination-area">
        <ul>
          <li className="active"></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <ToastContainer position="bottom-right" hideProgressBar={true} />
    </div>
  );
}

export default PasswordResetFinish;
