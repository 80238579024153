import React from "react";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

function CartPreview({ totalCartItems }) {
  return (
    <div className="cart-menu-dropdown">
      <div className="cart-headig">
        <h6>Cart ({totalCartItems} Items)</h6>
        <Link to="">Go to cart</Link>
      </div>
      {totalCartItems > 0 ? (
        <div className="all-add-carts">
          <Link to="#" className="add-to-item">
            <div className="row">
              <div className="col-3">
                <div className="add-to-img">
                  <img src="/images/add-to-img.png" alt="" />
                </div>
              </div>
              <div className="col-7">
                <div className="add-to-cont">
                  <p>Epicurus in animis nostris inesse</p>
                  <h6>999.999,99 / mês</h6>
                </div>
              </div>
              <div className="col-2">
                <div className="add-to-number">
                  <p>99</p>
                </div>
              </div>
            </div>
          </Link>
          <Link to="#" className="add-to-item">
            <div className="add-to-img">
              <img src="/images/add-to-img.png" alt="" />
            </div>
            <div className="add-to-cont">
              <p>Epicurus in animis nostris inesse</p>
              <h6>999.999,99 / mês</h6>
            </div>
            <div className="add-to-number">
              <p>99</p>
            </div>
          </Link>
          <Link to="#" className="add-to-item">
            <div className="add-to-img">
              <img src="/images/add-to-img.png" alt="" />
            </div>
            <div className="add-to-cont">
              <p>Epicurus in animis nostris inesse</p>
              <h6>999.999,99 / mês</h6>
            </div>
            <div className="add-to-number">
              <p>99</p>
            </div>
          </Link>
        </div>
      ) : (
        <div className="empty-add-carts">
          <div className="empty-content">
            <img src="/images/add-to-img.png" alt="" />
            <p>You cart is empty. Start Shopping</p>
          </div>
        </div>
      )}
      <button className="checkout-btn">
        {" "}
        Checkout
        <ArrowForwardIosIcon />
      </button>
    </div>
  );
}

export default CartPreview;
