import React from "react";

function Profile() {
  return (
    <div>
      <h2>User Profile Page</h2>
    </div>
  );
}

export default Profile;
