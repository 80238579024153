import React from "react";

function Privacy() {
  return (
    <div>
      <h2>Privacy Page</h2>
    </div>
  );
}

export default Privacy;
