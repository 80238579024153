import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import UserService from "../../services/user.service";
import firebase from "firebase/app";
import "firebase/auth";
import AuthService from "../../services/AuthService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Register({ handleAuth }) {
  const { register, handleSubmit, errors, watch } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  const [showAlmostThere, setShowAlmostThere] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState();
  const history = useHistory();

  const signInGoogle = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    socialSignIn(provider);
  };

  const signInFacebook = () => {
    var provider = new firebase.auth.FacebookAuthProvider();
    socialSignIn(provider);
  };

  const socialSignIn = (provider) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //var token = result.credential.accessToken;
        // The signed-in user info.
        //var user = result.user;

        let socialAuthUser = {};
        socialAuthUser.email = result.user.email;
        socialAuthUser.displayName = result.user.displayName;
        socialAuthUser.profilePhotoUrl = result.user.photoURL;

        AuthService.socialLogin(socialAuthUser)
          .then(
            (result) => {
              console.log(result);
              toast.success("Login successful");
              history.push("/profile");
              handleAuth(true);
            },
            (error) => {
              toast.error("Login failed");
            }
          )
          .catch((error) => {
            toast.error("Login failed");
          });
      })
      .catch(function (error) {
        // Handle Errors here.
        //var errorCode = error.code;
        //var errorMessage = error.message;
        // The email of the user's account used.
        //var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        //var credential = error.credential;
        // ...
        toast.error("Login failed");
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    let user = {};
    user.email = data.email;
    user.login = data.email;
    user.password = data.password;
    user.langKey = "pt-PT";
    UserService.createAccount(user).then(
      (result) => {
        setShowAlmostThere(true);
        setRegisteredEmail(user.email);
      },
      (error) => {}
    );
  };

  return (
    <div>
      {showAlmostThere ? (
        <div className="sign-up-area almost-signup">
          <div className="wid-800">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="95"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "95%" }}
              >
                <span className="progress-ps">95%</span>
              </div>
            </div>
            <div className="pd-area">
              <div className="profile-img">
                <img
                  src="/images/car/boris-m-_CiyeM2kvqs-unsplash.jpg"
                  alt=""
                />
              </div>

              <h2 className="sign-heading">Almost there,</h2>
              <h6>
                Please confirm your account by clicking on the link sent to{" "}
                <Link to="/"> {registeredEmail} </Link>
                email address
              </h6>
              {/* <h6>
                Didn’t receive any mail <a href="/">resend mail</a>
              </h6> */}
            </div>
          </div>

          <div className="pagination-area">
            <ul>
              <li className="active"></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="sign-up-area user-signup">
          <div className="wid-800">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="70"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "10%" }}
              >
                {/* <span className="progress-ps">50%</span> */}
              </div>
            </div>
            <h2 className="sign-heading">Sign Up USER</h2>
            <div className="sing-up-flex">
              <div className="sing-up-left">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="input-box">
                    <label htmlFor="">E-mail </label>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      className="email"
                      name="email"
                      ref={register({ required: true })}
                    />
                    {errors.email ? (
                      <div className="valid-email">
                        <p>Please enter a valid mail</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="input-box">
                    <label htmlFor="">Password </label>
                    <input
                      type="password"
                      placeholder="Senha"
                      className="password"
                      name="password"
                      ref={register({
                        required: "You must specify a password",
                        minLength: {
                          value: 6,
                          message: "Password must have at least 6 characters",
                        },
                      })}
                    />
                    {errors.password && (
                      <div className="valid-email">
                        <p>{errors.password.message}</p>
                      </div>
                    )}
                  </div>
                  <div className="input-box">
                    <label htmlFor="">Password Confirmation </label>
                    <input
                      type="password"
                      placeholder="Senha"
                      className="password"
                      name="passwordConfirm"
                      ref={register({
                        validate: (value) =>
                          value === password.current ||
                          "The passwords do not match",
                      })}
                    />
                    {errors.passwordConfirm && (
                      <div className="valid-email">
                        <p>{errors.passwordConfirm.message}</p>
                      </div>
                    )}
                  </div>

                  <div className="input-box">
                    <div className="check-box">
                      <input
                        type="checkbox"
                        id="checkbox1"
                        name="termsCheck"
                        onChange={() => {}}
                        ref={register({ required: true })}
                      />
                      <label htmlFor="checkbox1">
                        I agree to the Marketplace{" "}
                        <Link to="/terms">Terms of Use</Link> and acknowledge
                        the <Link to="/privacy">Privacy Policy</Link>
                      </label>
                    </div>
                  </div>
                  {errors.termsCheck ? (
                    <div className="valid-email">
                      <p>You have to agree with the terms</p>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="input-box">
                    <input
                      type="submit"
                      value="Registar"
                      className="theme-btn col-000"
                    />
                  </div>
                </form>
              </div>
              <div className="sing-up-right">
                <h6>Entrar com Redes Sociais</h6>
                <div className="face-book-google">
                  <Link to="#" onClick={signInFacebook} className="face-book">
                    <img src="/images/facebook-og.svg" alt="" />
                    <span>Continue with Facebook</span>
                  </Link>
                  <Link
                    to="#"
                    onClick={signInGoogle}
                    className="continue-google"
                  >
                    <img src="/images/google-colors.svg" alt="" />
                    <span>Continue with Google</span>
                  </Link>
                </div>
                <p>
                  By continuing, I agree to the MKB Terms of Use and acknowledge
                  the MKB Privacy Policy. The MKB group may send me marketing
                  emails. I can opt-out at any time through my settings.
                </p>
                <div className="already-account">
                  <p>
                    Already have an account?<Link to="/">Sign In</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pagination-area">
            <ul>
              <li className="active"></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
