import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL } from "../../Constants";
import AuthService from "../../services/AuthService";
import { convertDateTimeFromServer, getAge } from "../../services/DateUtils";
import ProductService from "../../services/ProductService";
import currencyFormat from "../../services/Utilities";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

function ProductCardList({ product }) {
  const [wishes, setWishes] = useState();
  const [wishesId, setWishesId] = useState();

  const addToCart = () => {
    AuthService.isAuthenticated().then((result) => {
      if (result.data !== undefined) {
        ProductService.addToCart(product.id).then((result) => {
          if (result.status === 200) {
            toast.success("Item adicionado ao carrinho de compras");
          }
        });
      } else {
        toast.warning(
          "Tem que ter uma conta para adicionar itens ao seu carrinho de compras, pode Registar uma ou efectuar o Login se já tiver registado."
        );
      }
    });
  };

  const addRemoveWishlist = (id) => {
    AuthService.isAuthenticated().then((result) => {
      if (result.data !== undefined) {
        if (wishes) {
          console.log(wishesId);
          ProductService.removeFavorite(wishesId).then((result) => {
            if (result.status === 200) {
              toast.warning("Item removido aos favoritos");
              ProductService.getFavorite(product.id).then((result) => {
                setWishes(result.data && result.data.id);
              });
            }
          });
        } else {
          ProductService.addToFavorite(id).then((result) => {
            if (result.data && result.data.id) {
              toast.success("Item adicionado aos favoritos");
              ProductService.getFavorite(product.id).then((result) => {
                setWishes(result.data && result.data.id);
              });
            }
          });
        }
      } else {
        toast.warning(
          "Tem que ter uma conta para adicionar aos favoritos, pode Registar uma ou efectuar o Login se já tiver registado."
        );
      }
    });
  };

  useEffect(() => {
    const isInWishlist = () => {
      AuthService.isAuthenticated().then((result) => {
        if (result.data !== undefined) {
          ProductService.getFavorite(product.id).then((result) => {
            setWishes(result.data && result.data.id);
            if (result.data) {
              setWishesId(result.data.id);
            }
          });
        }
      });
    };
    isInWishlist();
  }, [product.id]);

  return (
    <div className="po-home-item">
      <div className="po-home-item-left">
        <div className="po-home-item-heading">
          <div className="po-home-item-headitop">
            <Link to={`/products/detail/${product.id}`}>
              <h4>{product.title}</h4>
            </Link>
            <p>
              <img src="/images/clock-border.png" alt="clock-border" />{" "}
              {getAge(
                convertDateTimeFromServer(product.publishDate),
                new Date()
              )}
            </p>
          </div>
          <p>
            {product.city.name}, {product.city.state.name}
          </p>
        </div>

        <div className="po-bed-items">
          <div className="po-bed-item">
            <div className="po-bedicon">
              <img src="/images/bedroom.svg" alt="what" />
            </div>
            <div className="po-bedi-cont">
              <h6>Bedrooms</h6>
              <p>4 bd</p>
            </div>
          </div>
          <div className="po-bed-item">
            <div className="po-bedicon">
              <img src="/images/bathroom.svg" alt="what" />
            </div>
            <div className="po-bedi-cont">
              <h6>Bedrooms</h6>
              <p>4 bd</p>
            </div>
          </div>
          <div className="po-bed-item">
            <div className="po-bedicon">
              <img src="/images/surface.svg" alt="what" />
            </div>
            <div className="po-bedi-cont">
              <h6>Surface</h6>
              <p>1.520 m2</p>
            </div>
          </div>
        </div>
        <div className="po-start-prices">
          <div className="po-start-price col-00af43">
            <h6>Starting Price (MZN)</h6>
            <p>
              {currencyFormat(product.price / 18)} <span>/mês</span>{" "}
              <img src="/images/information-sm.png" alt="" />
            </p>
          </div>
          <div className="po-start-price">
            <h6>Total Price (MZN)</h6>
            <p>{currencyFormat(product.price)}</p>
          </div>
          <div className="po-start-card">
            <button className="bg-0033a1" onClick={addToCart}>
              <i className="fas fa-cart-arrow-down"></i> Add to Cart
            </button>
            <button className="bg-0AA0E4">
              <img src="/images/chats.png" alt="chats" />
            </button>
          </div>
        </div>
      </div>
      <div className="po-home-item-right">
        <div className="po-home-img">
          <Link to="/">
            <img
              src={`${API_URL}get-product-thumb/${product.id}/${product.id}.png`}
              alt={product.title}
              style={{ height: "320px" }}
            />
          </Link>
        </div>
        <div className="po-home-cont-top">
          <div className="po-photo-vi">
            <span>
              <img src="/images/gallery.svg" alt="Photos" />+
            </span>
          </div>
          <div className="po-share">
            <span>
              <img src="/images/share.svg" alt="share" />
            </span>
            <span>
              {wishes ? (
                <Link
                  to="#"
                  onClick={() => {
                    addRemoveWishlist(product.id);
                  }}
                  className="card_btn_icon_fav"
                >
                  <FavoriteIcon />
                </Link>
              ) : (
                <Link
                  to="#"
                  onClick={() => {
                    addRemoveWishlist(product.id);
                  }}
                  className="card_btn_icon"
                >
                  <FavoriteBorderIcon />
                </Link>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCardList;
