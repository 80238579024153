const environment = () => {
  /* if (process.env.NODE_ENV !== "production") {
    return "http://localhost:8080/api/";
  } else {
    return "http://client-mrp.sarifostech.com/api/";
  } */
  return "http://client-mrp.sarifostech.com/api/";
  //return "http://localhost:8080/api/";
};
export const API_URL = environment();
