import React from "react";
import { Link } from "react-router-dom";

function CityItem({ data }) {
  return (
    <div className="agencies-city-items">
      {data.map((v, k) => {
        return (
          <Link to="/" className="agencies-city-item" key={v.cityId}>
            <div className="agencies-city-img">
              <img src={`/images/city_${v.cityId}.jpg`} alt="" />
            </div>
            <p>
              {v.city} ({v.count})
            </p>
          </Link>
        );
      })}
      {/* <Link to="/" className="agencies-city-item">
        <div className="agencies-city-img">
          <img src="/images/city-2.jpg" alt="" />
        </div>
        <p>Port Breanatown (29)</p>
      </Link>
      <Link to="/" className="agencies-city-item">
        <div className="agencies-city-img">
          <img src="/images/city-3.jpg" alt="" />
        </div>
        <p>South Dallas (49)</p>
      </Link>
      <Link to="/" className="agencies-city-item">
        <div className="agencies-city-img">
          <img src="/images/city-4.jpg" alt="" />
        </div>
        <p>Aimeeland (34)</p>
      </Link>
      <Link to="/" className="agencies-city-item">
        <div className="agencies-city-img">
          <img src="/images/city-5.jpg" alt="" />
        </div>
        <p>Marquardtton (87)</p>
      </Link>
      <Link to="/" className="agencies-city-item">
        <div className="agencies-city-img">
          <img src="/images/city-6.jpg" alt="" />
        </div>
        <p>Adolphborough (329)</p>
      </Link>
      <Link to="/" className="agencies-city-item">
        <div className="agencies-city-img">
          <img src="/images/city-7.jpg" alt="" />
        </div>
        <p>Port Breanatown (29)</p>
      </Link>
      <Link to="/" className="agencies-city-item">
        <div className="agencies-city-img">
          <img src="/images/city-8.jpg" alt="" />
        </div>
        <p>South Dallas (49)</p>
      </Link>
      <Link to="/" className="agencies-city-item">
        <div className="agencies-city-img">
          <img src="/images/city-9.jpg" alt="" />
        </div>
        <p>Aimeeland (34)</p>
      </Link>
      <Link to="/" className="agencies-city-item">
        <div className="agencies-city-img">
          <img src="/images/city-10.jpg" alt="" />
        </div>
        <p>Marquardtton (87)</p>
      </Link> */}
    </div>
  );
}

export default CityItem;
