import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

function PasswordReset() {
  const { register, handleSubmit, errors } = useForm();
  const [showResend, setShowResend] = useState(false);
  const [captcha, setCaptcha] = useState();

  const onCaptchaChange = (value) => {
    setCaptcha(value);
  };

  //const [recaptchaRef, setRecaptchaRef] = useState();
  const recaptchaRef = useRef(null);

  const onSubmit = (data) => {
    if (captcha) {
      recaptchaRef.current.reset();
      setCaptcha(null);
      AuthService.resetPasswordInit(data.email)
        .then(
          (result) => {
            console.log(result);
            if (result.status === 200) {
              toast.success("Request submitted");
              setShowResend(true);
            }
          },
          (error) => {
            toast.error(
              "Email address isn't registered! Please check and try again"
            );
          }
        )
        .catch((error) => {
          console.log({ ...error });
        });
    } else {
      toast.error("Please fill check the captcha box");
    }
  };

  return (
    <div className="sign-up-area cant-login">
      <div className="wid-410">
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: "100%" }}
          >
            {/* <span className="progress-ps">75%</span> */}
          </div>
        </div>
        <div className="pd-area">
          <div className="profile-img">
            <img src="/images/car/boris-m-_CiyeM2kvqs-unsplash.jpg" alt="" />
          </div>

          <h3 className="sign-heading">Can’t login?</h3>
          <h6>We’ll send a recovery link to</h6>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-box">
              <label htmlFor="">Email </label>
              <input
                type="email"
                placeholder="you@example.com"
                name="email"
                className="email"
                ref={register({ required: true })}
              />
              {errors.email ? (
                <div className="valid-email">
                  <p>Please enter a valid mail</p>
                </div>
              ) : (
                ""
              )}
              <ReCAPTCHA
                ref={recaptchaRef}
                className="text-center"
                sitekey="6Ld0adcZAAAAAADr5FXjD75XI2PQsCDZ_JBJEFX3"
                onChange={onCaptchaChange}
              />
              {errors.recaptcha ? "Please fill recaptcha" : ""}
            </div>
            <input
              type="submit"
              value="Send recovery link"
              className="theme-btn col-9496a1"
            />
          </form>
          {showResend ? (
            <div className="resend-link">
              <h6>Didn’t receive any link? </h6>{" "}
              <button
                className="btn"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Resend link
              </button>
            </div>
          ) : (
            ""
          )}

          <Link to="/login" className="return-log">
            <img src="/images/leftcolor.svg" alt="" /> Return to login
          </Link>
        </div>
      </div>

      {/* <div className="error-box">
        <img src="/images/error.svg" alt="" />
        <div className="error-content">
          <h4>Error</h4>
          <p>Please enter a valid email before continue.</p>
        </div>
        <div className="error-cloge">
          <img src="/images/circle-fill.svg" alt="" />
        </div>
      </div> */}

      <div className="pagination-area">
        <ul>
          <li className="active"></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <ToastContainer position="bottom-right" hideProgressBar={true} />
    </div>
  );
}

export default PasswordReset;
