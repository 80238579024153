import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../services/AuthService";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import MessageIcon from "@material-ui/icons/Message";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import SettingsIcon from "@material-ui/icons/Settings";

function ProfileSideBar() {
  const [userProfile, setUserProfile] = useState({});

  useEffect(() => {
    const getAuthUser = () => {
      AuthService.getAuthenticatedUser().then((result) => {
        console.log(result);
        setUserProfile(result.data);
      });
    };

    getAuthUser();
  }, []);

  return (
    <div className="left-menu-area">
      <div className="mobile-menu-icon">
        <i className="fas fa-angle-double-right"></i>
      </div>
      <div className="left-profile-headign">
        <Link to="#">
          <img src={userProfile.imageUrl} alt="profile" />
        </Link>
        <Link to="#">
          <h4>
            {userProfile.firstName} {userProfile.lastName}
          </h4>
        </Link>
        <Link to="#">
          <p>{userProfile.email}</p>
        </Link>
        <Link to="#" className="profile-edit-details">
          edit details
        </Link>
      </div>

      <div className="left-main-menu-area">
        <div className="left-main-menu-item">
          <h6 className="left-main-menu-item-heading">NOTIFICATIONS</h6>
          <ul>
            <li>
              <Link to="#">
                <NotificationsActiveIcon /> Notifications
              </Link>
            </li>
            <li>
              <Link to="#" className="active">
                <MessageIcon /> Messages
              </Link>
            </li>
          </ul>
        </div>
        <div className="left-main-menu-item">
          <h6 className="left-main-menu-item-heading">MANAGEMENT</h6>
          <ul>
            <li>
              <Link to="#">
                <FavoriteIcon /> Favorites
              </Link>
            </li>
            <li>
              <Link to="#" className="active">
                <AddShoppingCartIcon /> Cart
              </Link>
            </li>
            <li>
              <Link to="#">
                <LocalMallIcon /> Orders
              </Link>
            </li>
          </ul>
        </div>
        <div className="left-main-menu-item">
          <h6 className="left-main-menu-item-heading">SETTINGS</h6>
          <ul>
            <li>
              <Link to="#">
                <SettingsIcon /> Account Settings
              </Link>
            </li>
          </ul>
        </div>
        {/* <div className="left-main-menu-item">
          <h6 className="left-main-menu-item-heading">HELP</h6>
          <ul>
            <li>
              <Link to="#">
                <i className="fas fa-question-circle"></i> Knowledge Base
              </Link>
            </li>
            <li>
              <Link to="#">
                <i className="fas fa-music"></i> Ticket
              </Link>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
}

export default ProfileSideBar;
