import React from "react";
import "./Navbar.css";

function TopBanner({ onClickHandle, showClose, message, bgColor, color }) {
  return (
    <div className="top-header" style={{ backgroundColor: bgColor }}>
      <p
        dangerouslySetInnerHTML={{ __html: message }}
        style={{ color: color }}
      ></p>
      {showClose ? (
        <div className="top-header-close" onClick={onClickHandle}>
          &times;
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default TopBanner;
