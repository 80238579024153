import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function AgentContactCard({ name, website, isAuth }) {
  const [sellerName, setSellerName] = useState("A+A");

  useEffect(() => {
    const setName = () => {
      setSellerName(name.replace(" ", "+"));
    };
    setName();
  }, [name]);

  return (
    <div className="col-lg-4">
      <div className="left-area">
        <div className="our-contact-area">
          <div className="profile-heading">
            <Link to="/" className="profile-pic">
              <img
                src={`https://ui-avatars.com/api/?name=${sellerName}`}
                alt="profile"
              />
            </Link>
            <div className="profile-cont">
              <h5>{name}</h5>
              <h6>{website}</h6>
              {/* <p>
                RERA# 11741 <span>{website}</span>
              </p> */}
            </div>
            <Link to="/">View Profile</Link>
          </div>

          <h6 className="contact-agent-heading">
            Contact Agent for more information.
          </h6>
          <div className="profile-up">
            <form action="">
              {!isAuth ? (
                <div>
                  <div className="input-box">
                    <label htmlFor="">Name </label>
                    <input
                      type="email"
                      placeholder="Enter you information"
                      className="name"
                    />
                  </div>
                  <div className="input-box">
                    <label htmlFor="">Email </label>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      className="email"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="input-box">
                <label htmlFor="">Phone</label>
                <input id="phone5" name="phone" type="tel" className="phone" />
              </div>
              <div className="input-box">
                <label htmlFor="">Message</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="6"
                  placeholder="Enter you message"
                ></textarea>
              </div>
              <div className="input-box">
                <input
                  type="submit"
                  value="Send Message"
                  className="theme-btn bg-0033A1"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentContactCard;
