import React, { useState } from "react";
import { Link } from "react-router-dom";
import ProductCardList from "../product/ProductCardList";
import ProfileSideBar from "./ProfileSideBar";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SearchIcon from "@material-ui/icons/Search";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";

function Wishlist({ wishList }) {
  const [selectedItems] = useState(0);

  return (
    <div className="page-wrap">
      <ProfileSideBar />
      <div className="big-page-area">
        <div className="page-heading-top">
          <h5>Favorites</h5>
          <ul className="page-link-big">
            <li>
              <Link to="#">
                Dashboard <ChevronRightIcon />{" "}
              </Link>
            </li>
            <li>
              <Link to="#">
                Favorites <ChevronRightIcon />
              </Link>
            </li>
            <li>
              <span>My Wishlist</span>
            </li>
          </ul>
        </div>
        <div className="big-page-favorites-bg">
          <div className="bg-select-all-product">
            <h5>{selectedItems} Selected</h5>
            <div className="bg-select-prod-items">
              <div className="bg-select-prod-item">
                <div className="all-select">
                  {" "}
                  <span></span>
                </div>
                <div className="menu-src-bar">
                  <form action="">
                    <input type="text" placeholder="Search for Item" />
                    <button className="src-icon">
                      <SearchIcon />
                    </button>
                  </form>
                </div>
              </div>

              <div className="bg-select-prod-item-heart">
                <div className="bg-select-prod-item">
                  <div className="flex-fav">
                    {" "}
                    <FavoriteBorderIcon />
                    <p>Unfavorite</p>
                  </div>
                </div>
                <div className="bg-select-prod-item">
                  <div className="flex-fav">
                    {" "}
                    <AddShoppingCartIcon />
                    <p>Add to Cart</p>
                  </div>
                </div>
                {/* <div className="bg-select-prod-item">
                  <div
                    className="all-select-delete"
                    data-toggle="modal"
                    data-target="#confirmation-dialog"
                  >
                    {" "}
                    <img
                      src="assets/image/5section/icons-trash-red.svg"
                      alt=""
                    />
                    <p>Remove from Cart</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="po-home-item-flex">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {wishList.map((v) => {
                    return (
                      <div className="box-warp po-home-items" key={v.id}>
                        <div className="big-se-pro-item">
                          {" "}
                          <span></span>
                        </div>
                        <ProductCardList product={v.product} />{" "}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wishlist;
