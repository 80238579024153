import React from "react";
import { Link } from "react-router-dom";

function ProductListSlide({ title, description, className, btnOne, btnTwo }) {
  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="banner-content">
              <div className="banner-btn">
                <Link to="/listing">{btnOne}</Link>
                <Link to="/agencies">{btnTwo}</Link>
              </div>
              <h2>{title}</h2>
              <h5>{description}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductListSlide;
