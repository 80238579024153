import axios from "axios";
import { API_URL } from "../Constants";
class DataUtils {
  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  openFile(type, data) {
    var isChromium = window.chrome,
      winNav = window.navigator,
      isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    if (isIEedge) {
      window.navigator.msSaveOrOpenBlob(
        this.dataURItoBlob("data:" + type + ";base64," + data)
      );
    } else if (isChromium) {
      window.open(
        URL.createObjectURL(
          this.dataURItoBlob("data:" + type + ";base64," + data)
        )
      );
    } else {
      window.open(
        "data:" + type + ";base64," + data,
        "_blank",
        "height=1024,width=600"
      );
    }
  }

  /* convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }; */

  convertBase64(file, cb) {
    var fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = function (e) {
      var base64Data = e.target.result.substr(
        e.target.result.indexOf("base64,") + "base64,".length
      );
      cb(base64Data);
    };
  }

  uploadFile(tempFile, config) {
    return axios.post(
      `${API_URL}uploadTempAttachment`,
      { ...tempFile },
      {
        accept: "application/json, text/plain, */*",
        "accept-encoding": "gzip, deflate, br",
        "accept-language": "en-US,en;q=0.8",
        "content-type": "application/json;charset=UTF-8",
        "user-agent": "React App",
        Connection: "keep-alive",
        "X-XSRF-TOKEN": "12fde46344e56c8faaf666888c5e24e9c3fafcef",
      },
      config
    );
  }
}

export default new DataUtils();
