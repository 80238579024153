export function convertDateTimeFromServer(date) {
  if (date) {
    return new Date(date);
  } else {
    return null;
  }
}

export function convertLocalDateFromServer(date) {
  if (date) {
    var dateString = date.split("-");
    return new Date(dateString[0], dateString[1] - 1, dateString[2]);
  }
  return null;
}

/* export function convertLocalDateToServer(date) {
  if (date) {
      return $filter('date')(date, 'yyyy-MM-dd');
  } else {
      return null;
  }
} */

export function dateformat() {
  return "yyyy-MM-dd";
}

export function getAge(date1, date2) {
  var age = getDiff(date1, date2, "days");
  var value = 0;
  if (parseInt(age) < 1) {
    //Idade em horas
    age = getDiff(date1, date2, "hours");
    if (parseInt(age) < 1) {
      //Idade em minutos
      age = getDiff(date1, date2, "minutes");
      if (parseInt(age) < 1) {
        //Idade em segundos
        age = getDiff(date1, date2, "seconds");
        if (age === 1) {
          value = age + " segundo";
        } else {
          value = age + " segundos";
        }
      } else {
        if (age === 1) {
          value = age + " minuto";
        } else {
          value = age + " minutos";
        }
      }
    } else {
      if (age === 1) {
        value = age + " hora";
      } else {
        value = age + " horas";
      }
    }
  } else {
    if (age === 1) {
      value = age + " dia";
    } else {
      value = age + " dias";
    }
  }

  return value;
}

export function getDiff(date1, date2, interval) {
  var second = 1000,
    minute = second * 60,
    hour = minute * 60,
    day = hour * 24,
    week = day * 7;
  date1 = new Date(date1);
  date2 = new Date(date2);
  var timediff = date2 - date1;
  if (isNaN(timediff)) return NaN;
  switch (interval) {
    case "years":
      return date2.getFullYear() - date1.getFullYear();
    case "months":
      return (
        date2.getFullYear() * 12 +
        date2.getMonth() -
        (date1.getFullYear() * 12 + date1.getMonth())
      );
    case "weeks":
      return Math.floor(timediff / week);
    case "days":
      return Math.floor(timediff / day);
    case "hours":
      return Math.floor(timediff / hour);
    case "minutes":
      return Math.floor(timediff / minute);
    case "seconds":
      return Math.floor(timediff / second);
    default:
      return undefined;
  }
}
