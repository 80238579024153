import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import currencyFormat from "../../services/Utilities";
import { useTranslation } from "react-i18next";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ShareIcon from "@material-ui/icons/Share";
import "../../App.css";
import { convertDateTimeFromServer, getAge } from "../../services/DateUtils";
import AuthService from "../../services/AuthService";
import ProductService from "../../services/ProductService";
import { toast } from "react-toastify";

function ProductCard({ productId, title, location, price, age, url }) {
  const { t } = useTranslation();
  const [wishes, setWishes] = useState();
  const [wishesId, setWishesId] = useState();

  const addRemoveWishlist = (id) => {
    AuthService.isAuthenticated().then((result) => {
      if (result.data !== undefined) {
        if (wishes) {
          //console.log(wishesId);
          ProductService.removeFavorite(wishesId).then((result) => {
            console.log(result);
            if (result.status === 200) {
              toast.warning("Item removido aos favoritos");
              if (productId) {
                ProductService.getFavorite(productId).then((result) => {
                  setWishes(result.data && result.data.id);
                });
              }
            }
          });
        } else {
          ProductService.addToFavorite(id).then((result) => {
            if (result.data && result.data.id) {
              toast.success("Item adicionado aos favoritos");
              if (productId) {
                ProductService.getFavorite(productId).then((result) => {
                  setWishes(result.data && result.data.id);
                });
              }
            }
          });
        }
      } else {
        toast.warning(
          "Tem que ter uma conta para adicionar aos favoritos, pode Registar uma ou efectuar o Login se já tiver registado."
        );
      }
    });
  };

  useEffect(() => {
    const loggedUser = localStorage.getItem("user");
    const isInWishlist = () => {
      if (loggedUser) {
        AuthService.isAuthenticated().then((result) => {
          result.data = result.data + "A";
          if (result.data !== "A" && productId) {
            ProductService.getFavorite(productId).then((result) => {
              setWishes(result.data && result.data.id);
              if (result.data) {
                setWishesId(result.data.id);
              }
            });
          }
        });
      }
    };
    isInWishlist();
  }, [productId]);

  return (
    <div className="col-lg-4 col-md-6">
      <div className="post-item">
        <div className="post-item-img">
          <Link to={`/products/detail/${productId}`}>
            <img
              src={url}
              alt=""
              style={{ objectFit: "cover", height: "250px" }}
            />
          </Link>
          <div className="po-home-cont-top">
            <div className="po-photo-vi">
              <span>
                <img src="/images/photo.png" alt="Photos" />+
              </span>
            </div>
            <div className="po-share">
              <span>
                <Link to="/" className="card_btn_icon">
                  <ShareIcon />
                </Link>
              </span>
              <span>
                {wishes ? (
                  <Link
                    to="#"
                    onClick={() => {
                      addRemoveWishlist(productId);
                    }}
                    className="card_btn_icon_fav"
                  >
                    <FavoriteIcon />
                  </Link>
                ) : (
                  <Link
                    to="#"
                    onClick={() => {
                      addRemoveWishlist(productId);
                    }}
                    className="card_btn_icon"
                  >
                    <FavoriteBorderIcon />
                  </Link>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="post-item-content">
          <div className="po-home-item-heading">
            <div className="po-home-item-headitop">
              <Link to={`/products/detail/${productId}`}>
                <h4>{title}</h4>
              </Link>
              <p>
                <img src="/images/clock-border.png" alt="clock-border" />{" "}
                {getAge(convertDateTimeFromServer(age), new Date())}
              </p>
            </div>
            <div className="post-flex">
              <p>{location}</p>
              <h5>
                {t("product.card.fee")} <span>{currencyFormat(price)}</span>
                <strong>MZN</strong>{" "}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
