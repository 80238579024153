import React from "react";
import { Link } from "react-router-dom";

function HomeSlide({ title, description, buttonTitle, buttonLink, image }) {
  return (
    <div className="slider-item ">
      <div className="landing-page-slider-item">
        <div className="landing-page-slider-img">
          <img src={image} alt="landing" />
        </div>

        <div className="container">
          <div className="landing-page-slider-content">
            <h2>{title}</h2>
            <p>{description}</p>
            <Link to={buttonLink} className="theme-btn bg-00a1e0">
              {buttonTitle}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSlide;
