import axios from "axios";
import { API_URL } from "../Constants";
import authHeader from "./auth-header";

class ProductService {
  getHomeItems(category) {
    return axios.get(`${API_URL}home-items/${category}`);
  }

  getLandingPageProducts() {
    return axios.get(`${API_URL}home-products`);
  }

  getHomeProducts(category) {
    return axios.get(`${API_URL}products-get/${category}`);
  }

  searchFilterQuery(
    state,
    searchString,
    category,
    subCategory,
    page,
    size,
    sort
  ) {
    return axios.get(
      `${API_URL}search-filter/${state}/${searchString}/${category}/${subCategory}`,
      { page, size, sort }
    );
  }

  searchFilter(state, category, subCategory, page, size, sort) {
    return axios.get(
      `${API_URL}search-filter/${state}/${category}/${subCategory}`,
      { page, size, sort }
    );
  }

  getOne(id) {
    return axios.get(`${API_URL}products/${id}`);
  }

  getAltImagesId(id) {
    return axios.get(`${API_URL}product-alt-images-id/${id}`);
  }

  addToFavorite(id) {
    return axios.get(`${API_URL}add-to-wish/${id}`, { headers: authHeader() });
  }

  removeFavorite(id) {
    return axios.delete(`${API_URL}wish-lists/${id}`, {
      headers: authHeader(),
    });
  }

  getFavorite(id) {
    return axios.get(`${API_URL}is-product-present/${id}`, {
      headers: authHeader(),
    });
  }

  getFavoriteList() {
    return axios.get(`${API_URL}user-list`, {
      headers: authHeader(),
    });
  }

  getCartList() {
    return axios.get(`${API_URL}user-cart-list`, {
      headers: authHeader(),
    });
  }

  getCartCount() {
    return axios.get(`${API_URL}user-cart-list-count`, {
      headers: authHeader(),
    });
  }

  getFavoriteCount() {
    return axios.get(`${API_URL}user-list-count`, {
      headers: authHeader(),
    });
  }

  getSimulationFile(simulation) {
    return axios.post(
      `${API_URL}simulation-generate`,
      { ...simulation },
      {
        headers: authHeader(),
      }
    );
  }

  getProductFields(id) {
    return axios.get(`${API_URL}get-product-extra-fields/${id}`, {
      headers: authHeader(),
    });
  }

  addToCart(id) {
    return axios.get(`${API_URL}add-to-cart/${id}`, {
      headers: authHeader(),
    });
  }
}

export default new ProductService();
