import { Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DataUtils from "../../services/DataUtils";
import LocationService from "../../services/LocationService";
import userService from "../../services/user.service";
import FileUpload from "./FileUpload";

function DealerRegisterData({ userData, setShowNext }) {
  const { register, handleSubmit, errors } = useForm();
  const [showAlmostThere, setShowAlmostThere] = useState(false);

  const onSubmit = (data) => {
    const company = {};
    company.name = data.name;
    company.phone = data.phone;
    company.website = data.website;
    company.documentNumber = data.documentNumber;
    company.documentType = data.documentType;
    company.nuit = data.nuit;
    company.user = userData;
    company.user.firstName = data.firstName;
    company.user.lastName = data.lastName;
    company.documentIds = documentIds;

    userService.createCompany(company).then(
      (response) => {
        if (response.status === 200) {
          setShowAlmostThere(true);
          toast.success("Account updated successfully");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  //Array holding uploaded temporary documents
  const [documentIds, setDocumentIds] = useState([]);

  const [openMapPicker, setOpenMapPicker] = useState(false);

  const [cities, setCities] = useState([]);

  const [companyFiscalNumberFile, setCompanyFiscalNumberFile] = useState(false);
  const [agencyCertFile, setAgencyCertFile] = useState(false);
  const [companyLicenseFile, setCompanyLicenseFile] = useState(false);
  const [managerFiscalNumberFile, setManagerFiscalNumberFile] = useState(false);
  const [managerIdFile, setManagerIdFile] = useState(false);

  const config = {
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log(percentCompleted);
    },
  };

  const handleFileSelection = (file, fileContentType, title) => {
    const fileData = {
      file: file,
      fileContentType: fileContentType,
      title: title,
    };

    DataUtils.uploadFile(fileData, config).then(
      (response) => {
        const auxIds = documentIds;
        auxIds.push(response.data);
        setDocumentIds(auxIds);
        if (title === "Agency Certification") {
          setAgencyCertFile(true);
        } else if (title === "Company Fiscal Number") {
          setCompanyFiscalNumberFile(true);
        } else if (title === "Company License") {
          setCompanyLicenseFile(true);
        } else if (title === "Manager Fiscal Number") {
          setManagerFiscalNumberFile(true);
        } else if (title === "Manager ID") {
          setManagerIdFile(true);
        }

        console.log(documentIds);
      },
      (error) => {
        console.log(error);
        //TODO error handle
      }
    );
  };

  useEffect(() => {
    const getCities = () => {
      LocationService.getAllCities().then((response) => {
        setCities(response.data);
      });
    };

    getCities();
  }, []);

  const openMap = () => {
    setOpenMapPicker(true);
    /* var mapboxgl = require("mapbox-gl/dist/mapbox-gl.js");

    mapboxgl.accessToken =
      "pk.eyJ1Ijoic3RiLW1hcmtldHBsYWNlIiwiYSI6ImNrZ2N2b3luODAxbnEycXM0eXhpN2V5bHUifQ.-BbjaY__uJHzzfgS1OIFpg";
    var map = new mapboxgl.Map({
      container: "map-box-container",
      style: "mapbox://styles/mapbox/streets-v11",
    }); */
  };

  return (
    <div>
      {!showAlmostThere ? (
        <div className="sign-up-area agency-information">
          <div className="wid-1100">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="90"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "90%" }}
              >
                <span className="progress-ps">90%</span>
              </div>
            </div>
            <div className="pd-area">
              <h4 className="sign-heading">Agency and Manager Information</h4>

              <div className="agency-information-area">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h5 className="co-info-he">Company Info</h5>
                  <div className="agency-information-items">
                    <div className="input-box">
                      <label htmlFor="">Legal Company Name* </label>
                      <input
                        type="text"
                        placeholder="Enter Legal Company Name"
                        name="name"
                        ref={register({
                          required: "Legal Company Name is required",
                        })}
                      />

                      {errors.name && (
                        <h6 className="errer-company">{errors.name.message}</h6>
                      )}
                    </div>
                    <div className="input-box">
                      <label htmlFor="">Website*</label>
                      <input
                        type="text"
                        placeholder="Enter Website"
                        name="website"
                        ref={register()}
                      />
                    </div>
                    <div className="input-box">
                      <label htmlFor="">Fiscal Number*</label>
                      <input
                        type="text"
                        placeholder="Enter Fiscal Number"
                        name="nuit"
                        ref={register({
                          required: "Please enter a valid Fiscal Number",
                          minLength: {
                            value: 9,
                            message: "Fiscal Number must have 9 characters",
                          },
                          maxLength: {
                            value: 9,
                            message: "Fiscal Number must have 9 characters",
                          },
                        })}
                      />
                      {errors.nuit && (
                        <h6 className="errer-fiscal">{errors.nuit.message}</h6>
                      )}
                    </div>

                    <div className="input-box">
                      <label htmlFor="">Company Phone</label>
                      <input
                        id="phone1"
                        name="phone"
                        type="tel"
                        className="phone"
                        ref={register()}
                      />
                    </div>

                    <div className="input-box">
                      <label htmlFor="">Address*</label>
                      <input
                        type="text"
                        placeholder="Enter Address"
                        name="address"
                        ref={register()}
                      />
                    </div>

                    <div className="input-box">
                      <label htmlFor="">City</label>
                      <div className="input-box-2">
                        <div className="city-items">
                          <select id="city" name="city" ref={register()}>
                            <option value="0" defaultValue>
                              City
                            </option>
                            {cities.map((v, k) => {
                              return (
                                <option value={v.id} key={k}>
                                  {v.name}
                                </option>
                              );
                            })}
                          </select>
                          {/* <h6 className="errer-city">Please choose a City</h6> */}
                        </div>
                        <div className="location-item">
                          <Link to="#" onClick={openMap}>
                            <img src="/images/location.svg" alt="location" />{" "}
                            <span>Get Location</span>{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h5 className="co-info-he">Manager Info</h5>
                  <div className="agency-information-items">
                    <div className="input-box">
                      <label htmlFor="fullName">First name*</label>
                      <input
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        ref={register({ required: "Please enter First Name" })}
                      />
                      {errors.firstName && (
                        <h6 className="errer-company">
                          {errors.firstName.message}
                        </h6>
                      )}
                    </div>
                    <div className="input-box">
                      <label htmlFor="">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Enter Last Name"
                        ref={register({ required: "Please enter Last Name" })}
                      />
                      {errors.lastName && (
                        <h6 className="errer-company">
                          {errors.lastName.message}
                        </h6>
                      )}
                    </div>

                    <div className="input-box">
                      <label htmlFor="">Document Type</label>
                      <input
                        type="text"
                        name="documentType"
                        placeholder="Enter Document Type"
                        ref={register({
                          required: "Please enter Document Type",
                        })}
                      />
                      {errors.documentType && (
                        <h6 className="errer-company">
                          {errors.documentType.message}
                        </h6>
                      )}
                    </div>

                    <div className="input-box">
                      <label htmlFor="">Document Number*</label>
                      <input
                        type="text"
                        placeholder="Please enter Document Number"
                        name="documentNumber"
                        ref={register({ required: "Please enter ID Number" })}
                      />
                      {errors.documentNumber && (
                        <h6 className="errer-company">
                          {errors.documentNumber.message}
                        </h6>
                      )}
                    </div>
                  </div>

                  <h5 className="co-info-he">Attachments</h5>
                  <div className="attachment-file-items">
                    {/* <div className="attachment-file-item">
                  <label>Company License* </label>
                  <div className="attach-file">
                    <div className="upload-file uploaded">
                      <div className="upload-file-img">
                        <img src="/images/upload-black.svg" alt="" />
                      </div>
                      <div className="uploading-time">
                        <p>Upload Company License</p>
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "25%" }}
                          >
                            <span className="progress-ps">25%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                    <FileUpload
                      title={"Company License"}
                      uploaded={companyLicenseFile}
                      selectFile={handleFileSelection}
                    />

                    <FileUpload
                      title={"Agency Certification"}
                      uploaded={agencyCertFile}
                      selectFile={handleFileSelection}
                    />

                    <FileUpload
                      title={"Company Fiscal Number"}
                      uploaded={companyFiscalNumberFile}
                      selectFile={handleFileSelection}
                    />

                    <FileUpload
                      title={"Manager Fiscal Number"}
                      uploaded={managerFiscalNumberFile}
                      selectFile={handleFileSelection}
                    />
                    <FileUpload
                      title={"Manager ID"}
                      uploaded={managerIdFile}
                      selectFile={handleFileSelection}
                    />
                  </div>
                  {/* <div className="input-box">
                <div className="check-box">
                  <input
                    type="checkbox"
                    id="checkbox3"
                    ref={register({
                      required: "You must agree to the terms of service",
                    })}
                    name="terms"
                    onChange={() => {}}
                  />
                  <label htmlFor="checkbox3">
                    I agree to the MKB Terms of Use and acknowledge the MKB
                    Privacy Policy
                  </label>
                  <p></p>
                  {errors.terms && (
                    <h6 className="errer-fiscal">{errors.terms.message}</h6>
                  )}
                </div>
                <p></p>
                <p></p>
                <h6 className="error-all">
                  * All fields with (asterichs) are mandatory
                </h6>
              </div> */}
                  <div className="back-register">
                    <h6 className="error-all">
                      * All fields with (asterichs) are mandatory
                    </h6>
                    <button
                      className="back-btn theme-btn bg-none"
                      onClick={() => {
                        setShowNext(false);
                      }}
                    >
                      Back
                    </button>
                    <button type="submit" className="back-btn theme-btn">
                      Register Account
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="pagination-area">
            <ul>
              <li className="active"></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>

          <Modal
            open={openMapPicker}
            onClose={() => {
              setOpenMapPicker(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div id="map-box-container"></div>
          </Modal>
        </div>
      ) : (
        <div className="sign-up-area almost-signup">
          <div className="wid-800">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="95"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "95%" }}
              >
                <span className="progress-ps">95%</span>
              </div>
            </div>
            <div className="pd-area">
              <div className="profile-img">
                <img
                  src="/images/car/boris-m-_CiyeM2kvqs-unsplash.jpg"
                  alt=""
                />
              </div>

              <h2 className="sign-heading">Almost there,</h2>
              <h6>
                Your account is under review. You will receive an email after
                the review is completed
              </h6>
            </div>
          </div>

          <div className="pagination-area">
            <ul>
              <li className="active"></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default DealerRegisterData;
