import React from "react";

function TermsAndCo() {
  return (
    <div style={{ margin: "100px", backgroundColor: "white", padding: "50px" }}>
      <p>
        <strong>
          <u>Termos e condi</u>
        </strong>
        <strong>
          <u>&ccedil;&otilde;es</u>
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Por favor, leia estes termos e condi&ccedil;&otilde;es com
        aten&ccedil;&atilde;o. Ao aceder a esta p&aacute;gina ou utilizar os
        servi&ccedil;os online, estar&aacute; a concordar com os seguintes
        termos e condi&ccedil;&otilde;es. N&atilde;o concordando com os mesmos,
        deve sair imediatamente desta p&aacute;gina.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>
            <u>Introdu&ccedil;&atilde;o</u>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        A plataforma de carros e casas do Standard Bank, S.A (doravante
        designada &ldquo;Marketplace&rdquo;) &eacute; um portal digital que
        conecta, directamente, vendedores e compradores de carros e casas,
        incluindo fornecedores de servi&ccedil;os relacionados.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>Contrato </strong>
        </li>
      </ul>
      <p>
        O usu&aacute;rio concorda que, ao clicar em "Registe-se agora",
        "Registe-se ao Marketplace", ou simplesmente "Registe -se", acedendo ou
        usando os nossos servi&ccedil;os, concorda em celebrar um contrato
        juridicamente vinculativo com o Marketplace do Standard Bank, SA.
      </p>
      <p>
        N&atilde;o concordando com este contrato ("Contrato" ou "Contrato do
        usu&aacute;rio"), n&atilde;o clique em "Associar-se agora" n&atilde;o
        aceda ou utilize qualquer um dos nossos servi&ccedil;os.
      </p>
      <p>
        Se desejar rescindir este contrato, a qualquer momento, pode
        faz&ecirc;-lo fechando a sua conta e n&atilde;o acedendo mais ou usando
        nossos servi&ccedil;os.
      </p>
      <p>
        Como visitante ou membro do aplicativo do Marketplace, a colecta, uso e
        partilha de seus dados pessoais est&atilde;o sujeitos &agrave;s normas
        de Privacidade de Dados .
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>Membros e visitantes </strong>
        </li>
      </ul>
      <p>
        Quando se regista e se associa ao Marketplace, ou se torna um
        usu&aacute;rio registado na plataforma, o usu&aacute;rio torna-se
        membro. Se tiver optado por n&atilde;o se registar em nossa plataforma,
        poder&aacute; aceder determinados recursos como um "Visitante".
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>Altera&ccedil;&otilde;es </strong>
        </li>
      </ul>
      <p>
        Este contrato pode ser alterado periodicamente. Caso sejam feitas
        altera&ccedil;&otilde;es significativas, ser&aacute; fornecido um aviso
        por meio dos nossos servi&ccedil;os, ou por outros meios, para lhe
        proporcionar a oportunidade de rever as altera&ccedil;&otilde;es antes
        que elas se tornem efectivas. Caso se oponha a alguma
        altera&ccedil;&atilde;o, poder&aacute; fechar a sua conta. O uso
        cont&iacute;nuo dos nossos servi&ccedil;os depois que publicamos ou
        enviamos um aviso sobre as altera&ccedil;&otilde;es nesses termos,
        significa que o usu&aacute;rio &nbsp;aceita e concorda com os termos
        actualizados na data efectiva.
      </p>
      <p>&nbsp;</p>
      <ol start="2">
        <li>
          <strong>
            <u>Obriga&ccedil;&otilde;es</u>
          </strong>
        </li>
      </ol>
      <p>
        <strong>
          <u>&nbsp;</u>
        </strong>
      </p>
      <ul>
        <li>
          <strong>Elegibilidade do servi&ccedil;o </strong>
        </li>
      </ul>
      <p>
        Os servi&ccedil;os n&atilde;o devem ser utilizados por menores de 18
        anos.
      </p>
      <p>&nbsp;</p>
      <p>
        Para usar os servi&ccedil;os, o usu&aacute;rio concorda que: (1) deve
        ter a "Idade M&iacute;nima" (descrita abaixo); (2) ter&aacute; apenas
        uma conta no aplicativo do Marketplace do Standard Bank, SA e que deve
        estar em seu nome verdadeiro; e (3) n&atilde;o est&aacute; impedido o
        Standard Bank, SA e entidades afiliadas, de usar os servi&ccedil;os.
        Criar uma conta com informa&ccedil;&otilde;es falsas &eacute; uma
        viola&ccedil;&atilde;o dos nossos termos e condi&ccedil;&otilde;es.
      </p>
      <p>&nbsp;</p>
      <p>
        "Idade m&iacute;nima" significa 18 anos de idade. No entanto, se a lei
        exigir que o usu&aacute;rio seja maior para que o Marketplace
        forne&ccedil;a legalmente os servi&ccedil;os sem o consentimento dos
        pais (incluindo o uso de seus dados pessoais), a Idade M&iacute;nima
        aquela prevista na lei.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>A sua conta </strong>
        </li>
      </ul>
      <p>
        Os membros s&atilde;o titulares de conta. O usu&aacute;rio concorda em
        usar uma senha forte e mant&ecirc;-la confidencial, sendo
        respons&aacute;vel por qualquer evento que ocorra na sua conta, a menos
        que a encerre ou denuncie o uso indevido, fundamentada e expressamente
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>Mensagens e notifica&ccedil;&otilde;es </strong>
        </li>
      </ul>
      <p>
        O usu&aacute;rio concorda que forneceremos notifica&ccedil;&otilde;es e
        mensagens das seguintes maneiras: (1) pelo aplicativo ou (2) enviando
        para os contactos fornecidos ( email e n&uacute;mero de telefone). O
        usu&aacute;rio concorda em manter seus detalhes actualizados.
      </p>
      <p>
        Reveja as configura&ccedil;&otilde;es para controlar e limitar as
        mensagens que recebe de n&oacute;s.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>Partilha </strong>
        </li>
      </ul>
      <p>
        O aplicativo permite o envio de mensagens, partilha de
        informa&ccedil;&otilde;es de produtos e servi&ccedil;os, perfil do
        usu&aacute;rio, eventos e actividades do Standard Bank, SA,
        subsidi&aacute;rias e parceiros .
      </p>
      <p>
        As informa&ccedil;&otilde;es e o conte&uacute;do partilhados pelo
        usu&aacute;rio ou pelos gestores do Marketplace, podem ser vistas por
        outros membros, visitantes e entidades autorizadas.
      </p>
      <p>
        Onde disponibilizamos as configura&ccedil;&otilde;es, honraremos as
        escolhas feitas pelo usu&aacute;rio sobre quem pode ver o
        conte&uacute;do ou as informa&ccedil;&otilde;es (por exemplo,
        conte&uacute;do da mensagem para seus destinat&aacute;rios,
        compartilhamento de conte&uacute;do apenas com as conex&otilde;es do
        aplicativo, restri&ccedil;&atilde;o da visibilidade do perfil nos
        mecanismos de pesquisa ou op&ccedil;&atilde;o de n&atilde;o notificar
        outras actualiza&ccedil;&otilde;es do seu perfil da plataforma).
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol start="3">
        <li>
          <strong>
            <u>Direitos e limites</u>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <strong>3.1. Licen&ccedil;a a plataforma </strong>
      </p>
      <p>
        O usu&aacute;rio concorda que o Marketplace pode aceder, armazenar,
        processar e usar qualquer informa&ccedil;&atilde;o e dados pessoais
        fornecidos de acordo com os termos de declara&ccedil;&atilde;o de
        privacidade (
        <a href="https://www.standardbank.co.mz/pt/Declaracao-de-privacidade">
          https://www.standardbank.co.mz/pt/Declaracao-de-privacidade
        </a>
        )
      </p>
      <p>&nbsp;</p>
      <p>
        Ao enviar sugest&otilde;es ou outros coment&aacute;rios sobre a nossa
        plataforma, o usu&aacute;rio concorda que o Standard Bank, S.A pode usar
        tais coment&aacute;rios para qualquer finalidade l&iacute;cita, sem
        nenhuma compensa&ccedil;&atilde;o para o usu&aacute;rio.
      </p>
      <p>
        O usu&aacute;rio concorda em fornecer apenas conte&uacute;do ou
        informa&ccedil;&otilde;es que n&atilde;o violem a lei nem os direitos de
        ningu&eacute;m (incluindo direitos de propriedade intelectual). Concorda
        tamb&eacute;m que as informa&ccedil;&otilde;es do seu perfil
        ser&atilde;o verdadeiras.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>3.2. Direitos de propriedade intelectual </strong>
      </p>
      <p>
        Excepto quando expressamente declarado este documento, os direitos de
        autor e toda propriedade intelectual contidos nesta p&aacute;gina
        (incluindo, sem limita&ccedil;&atilde;o, desenho, texto, som,
        grava&ccedil;&otilde;es de &aacute;udio ou v&iacute;deo, imagens ou{" "}
        <em>links</em>) s&atilde;o propriedade do Standard Bank,
        subsidi&aacute;rias e parceiros. Deste modo, n&atilde;o podem ser
        reproduzidos, transmitidos, publicados, executados, difundidos,
        adaptados, distribu&iacute;dos, partilhados, licenciados, alterados ou
        utilizados ao todo ou em parte sem o consentimento expl&iacute;cito e
        por escrito do Standard Bank.
      </p>
      <p>
        Todas marcas registadas, servi&ccedil;os e logotipos usados nesta
        p&aacute;gina s&atilde;o propriedade do Standard Bank e/ou de parceiros
        identificados nesta p&aacute;gina. Nenhuma licen&ccedil;a ou direito
        ser&aacute; concedida e seu acesso ao esta p&aacute;gina ou
        utiliza&ccedil;&atilde;o dos servi&ccedil;os digitais n&atilde;o deve
        ser interpretado como concess&atilde;o, por implica&ccedil;&atilde;o,
        preclus&atilde;o ou de outro modo, qualquer licen&ccedil;a ou direito de
        uso de qualquer marca registada, servi&ccedil;os ou logos que aparecem
        na p&aacute;gina sem consentimento escrito do Standard Bank ou
        parceiros.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>3.3. Processamento automatizado </strong>
      </p>
      <p>
        O Standard Bank, S.A usa as informa&ccedil;&otilde;es e dados fornecidos
        pelos usu&aacute;rios para fazer recomenda&ccedil;&otilde;es, criar
        conte&uacute;do, oferecer produtos relevantes, etc.
      </p>
      <ol start="4">
        <li>
          <strong>Cancelamento</strong>
        </li>
      </ol>
      <p>
        O usu&aacute;rio e o Standard Bank, S.A podem rescindir este contrato a
        qualquer momento, mediante notifica&ccedil;&atilde;o ao outro. Na
        rescis&atilde;o, o usu&aacute;rio da plataforma perde o direito de
        aceder ou usar os servi&ccedil;os do aplicativo. No entanto,
        manter-se-&aacute; o seguinte:
      </p>
      <ul>
        <li>
          Os direitos do Standard Bank, S.A de usar e divulgar
          coment&aacute;rios do usu&aacute;rio;
        </li>
        <li>
          Os direitos dos membros e/ou visitantes de compartilhar novamente o
          conte&uacute;do e as informa&ccedil;&otilde;es que foram partilhadas
          na plataforma.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol start="5">
        <li>
          <strong>
            <u>Outras condi&ccedil;&otilde;es</u>
          </strong>
        </li>
      </ol>
      <p>O usu&aacute;rio concorda que vai:</p>
      <ul>
        <li>
          Cumprir com todas as leis aplic&aacute;veis, incluindo, sem
          limita&ccedil;&atilde;o, leis de privacidade, leis de propriedade
          intelectual, leis anti-spam;
        </li>
        <li>
          Fornecer informa&ccedil;&otilde;es precisas e manter-nos actualizados;
        </li>
        <li>Usar o seu nome verdadeiro no seu perfil; e</li>
        <li>Usar o aplicativo de maneira profissional.</li>
      </ul>
      <p>&nbsp;</p>
      <p>O usu&aacute;rio concorda que n&atilde;o ir&aacute;:</p>
      <ul>
        <li>
          Criar uma identidade falsa na plataforma, deturpar a sua identidade,
          criar um perfil de membro para qualquer pessoa que n&atilde;o seja ele
          (uma pessoa real) ou usar ou tentar usar a conta de outra pessoa;
        </li>
        <li>
          Desenvolver, dar suporte ou usar <em>software</em>, dispositivos,{" "}
          <em>scripts</em>, rob&ocirc;s ou qualquer outro meio ou processo
          (incluindo rastreadores, <em>plug-ins</em> e complementos de navegador
          ou qualquer outra tecnologia) para raspar os servi&ccedil;os ou copiar
          perfis e outros dados na plataforma;
        </li>
        <li>
          Substituir qualquer recurso de seguran&ccedil;a ou ignorar ou
          contornar qualquer controle de acesso;
        </li>
        <li>
          Divulgar informa&ccedil;&otilde;es que n&atilde;o tem o consentimento
          em divulgar (como informa&ccedil;&otilde;es confidenciais de outras
          pessoas;
        </li>
        <li>Violar os direitos de propriedade intelectual de terceiros;</li>
        <li>
          Violar a propriedade intelectual ou outros direitos do Standard Bank,
          S.A, incluindo, sem limita&ccedil;&atilde;o, (i) copiar ou distribuir
          os v&iacute;deos de aprendizado ou outros materiais ou (ii) copiar ou
          distribuir a tecnologia fornecida, a menos que seja permitida sob
          licen&ccedil;as de c&oacute;digo aberto; (iii) usando a palavra
          "Standard Bank" ou nossos log&oacute;tipos em qualquer nome comercial,
          email ou URL, excepto conforme estabelecido nas directrizes da marca;
        </li>
        <li>
          Publicar qualquer coisa que contenha v&iacute;rus de software,{" "}
          <em>worm</em>s ou qualquer outro c&oacute;digo nocivo;
        </li>
        <li>
          Violar as pol&iacute;ticas da plataforma digital ou quaisquer termos
          adicionais relativos a um servi&ccedil;o espec&iacute;fico que
          s&atilde;o fornecidos aquando da inscri&ccedil;&atilde;o .
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol start="6">
        <li>
          <strong>
            <u>Reclama&ccedil;&otilde;es sobre conte&uacute;do</u>
          </strong>
        </li>
      </ol>
      <p>
        Respeitamos os direitos de propriedade intelectual de terceiros.
        Exigimos que as informa&ccedil;&otilde;es publicadas pelos
        usu&aacute;rios sejam precisas e n&atilde;o violem os direitos de
        propriedade intelectual ou outros direitos de terceiros.
      </p>
      <p>&nbsp;</p>
      <ol start="7">
        <li>
          <strong>
            <em>
              <u>Disclaimer </u>
            </em>
          </strong>
        </li>
      </ol>
      <p>
        A informa&ccedil;&atilde;o, produtos e servi&ccedil;os contidos ou
        acedidos atrav&eacute;s desta p&aacute;gina s&atilde;o fornecidos no
        estado em que encontram e n&atilde;o ser&atilde;o sujeitos &agrave;
        verifica&ccedil;&atilde;o ou avalia&ccedil;&atilde;o&nbsp; por nenhum
        membro do Standard Bank em rela&ccedil;&atilde;o a sua aptid&atilde;o
        para realiza&ccedil;&atilde;o de investimentos.&nbsp; Tal
        informa&ccedil;&atilde;o, produtos e servi&ccedil;os s&atilde;o
        fornecidos somente para informa&ccedil;&atilde;o geral e o
        usu&aacute;rio deve solicitar opini&atilde;o de especialistas ou obter
        verifica&ccedil;&atilde;o independente da informa&ccedil;&atilde;o e
        material contidos nesta p&aacute;gina antes de tomar uma decis&atilde;o
        sobre a sua aquisi&ccedil;&atilde;o.
      </p>
      <p>&nbsp;</p>
      <p>
        O Standard Bank n&atilde;o garante a verdade, veracidade,
        adequabilidade, completude ou razoabilidade da informa&ccedil;&atilde;o
        e materiais contidos ou acedidos atrav&eacute;s desta p&aacute;gina e
        nega responsabilidade sobre quaisquer erros ou omiss&otilde;es nas
        informa&ccedil;&otilde;es e materiais. Nenhuma garantia de qualquer
        tipo, impl&iacute;cita, explicita ou estatut&aacute;ria (incluindo, sem
        limita&ccedil;&atilde;o, garantias de t&iacute;tulo,
        comercializa&ccedil;&atilde;o, qualidade satisfat&oacute;ria, n&atilde;o
        infra&ccedil;&atilde;o de direitos de propriedade intelectual de
        terceiros, aptid&atilde;o, aus&ecirc;ncia de v&iacute;rus ou
        c&oacute;digos nocivos) ser&aacute; fornecida em rela&ccedil;&atilde;o a
        informa&ccedil;&atilde;o, produtos e servi&ccedil;os dispon&iacute;veis
        na p&aacute;gina.
      </p>
      <p>
        Em circunst&acirc;ncia alguma, o Standard Bank ser&aacute;
        responsabilizado, independentemente da forma de ac&ccedil;&atilde;o, por
        qualquer falha de desempenho, sistema, servidor ou falha de
        conex&atilde;o, erro, omiss&atilde;o, interrup&ccedil;&atilde;o,
        viola&ccedil;&atilde;o de seguran&ccedil;a, v&iacute;rus, c&oacute;digo
        nocivo, corrup&ccedil;&atilde;o, atraso em opera&ccedil;&otilde;es,
        transmiss&atilde;o, erro de transmiss&atilde;o ou indisponibilidade de
        servi&ccedil;o na tentativa de aceder a esta p&aacute;gina, mesmo que o
        Standard Bank tenha sido alertado sobre tal possibilidade.
      </p>
      <p>&nbsp;</p>
      <p>
        Em nenhum caso o Standard Bank ser&aacute; responsabilizado por si ou
        terceiros por danos, perdas, despesas ou quaisquer custos (incluindo,
        sem limita&ccedil;&atilde;o, quaisquer danos directos, indirectos,
        especiais, incidentais ou consequenciais, perda de lucros ou perda de
        oportunidade) como resultado do uso da p&aacute;gina, ou
        confian&ccedil;a em qualquer informa&ccedil;&atilde;o, produtos ou
        servi&ccedil;os oferecidos nesta p&aacute;gina, independentemente da
        forma de ac&ccedil;&atilde;o, mesmo que o Standard Bank tenha sido
        alertado sobre a possibilidade de tais perdas.
      </p>
      <p>&nbsp;</p>
      <ol start="8">
        <li>
          <strong>
            <em>
              <u>Hyperlinks</u>
            </em>
          </strong>
        </li>
      </ol>
      <p>
        Para a sua conveni&ecirc;ncia, o Standard Bank, poder&aacute; incluir{" "}
        <em>hyperlinks</em> para as p&aacute;ginas da internet operadas por
        terceiros. Tais p&aacute;ginas n&atilde;o est&atilde;o sob controlo do
        Standard Bank e, portanto, o Standard Bank, n&atilde;o pode assumir
        responsabilidade sobre o conte&uacute;do ou consequ&ecirc;ncias
        decorrentes do acesso destas p&aacute;ginas. Ademais, os{" "}
        <em>hyperlinks</em> fornecidos nesta p&aacute;gina n&atilde;o devem ser
        considerados ou&nbsp; interpretados como endosso ou
        verifica&ccedil;&atilde;o das p&aacute;ginas e seu conte&uacute;do pelo
        Standard Bank. O usu&aacute;rio concorda que o seu acesso a estas
        p&aacute;ginas &eacute; de sua inteira responsabilidade e sujeita-se aos
        termos de uso nelas contidos.
      </p>
      <p>&nbsp;</p>
      <ol start="9">
        <li>
          <strong>
            <u>Indemniza&ccedil;&atilde;o</u>
          </strong>
        </li>
      </ol>
      <p>
        O usu&aacute;rio concorda em indemnizar e isentar o Standard Bank de
        todos os danos, perdas, despesas e custos (incluindo custos legais)
        sofridos ou incorridos pelo Standard Bank, pelas seguintes
        raz&otilde;es:
      </p>
      <ol>
        <li>Acesso a p&aacute;gina;</li>
        <li>
          Acesso a esta p&aacute;gina por terceiros utilizando as suas
          credencias e senhas;
        </li>
        <li>
          Viola&ccedil;&atilde;o dos termos e condi&ccedil;&otilde;es de acesso;
          e
        </li>
        <li>
          Qualquer viola&ccedil;&atilde;o decorrente do facto de terceiros terem
          acedido esta p&aacute;gina usando as suas credenciais e senhas.
        </li>
      </ol>
    </div>
  );
}

export default TermsAndCo;
