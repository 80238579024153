import React from "react";
import { Link } from "react-router-dom";
import AgencyCard from "./AgencyCard";

function Agencies() {
  return (
    <div>
      <div className="agencie-page">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="home-top-menu">
                <ul>
                  <li>
                    <Link to="/">All</Link>
                  </li>
                  <li>
                    <Link to="/" className="active">
                      Agencies
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12">
              <div className="filter-src-warp">
                <div className="filter-src-area">
                  <div className="fl-src">
                    <p>
                      <img src="/images/search-wh-sm.png" alt="search" />
                      12.540 Results
                    </p>
                  </div>
                  <div className="fl-filter">
                    <p>
                      <img src="/images/filtertt.png" alt="FILTERS" />
                      FILTERS
                    </p>
                  </div>

                  <div className="location-src">
                    <div className="fl-form">
                      <p>LOCATION</p>
                      <input
                        type="text"
                        placeholder="Maputo, Central, Moçambique"
                      />
                    </div>
                    <div className="fl-form">
                      <p>AGENCY</p>
                      <input type="text" placeholder="Name of Agency Here" />
                    </div>
                  </div>

                  <div className="fl-room active">
                    <p>Services</p>
                    <i className="fas fa-caret-down"></i>
                  </div>
                  <div className="fl-others">
                    <p>Others</p>
                    <i className="fas fa-caret-down"></i>
                  </div>
                </div>

                <div className="fl-clear-short">
                  <div className="fl-clears">
                    <div className="fl-clear">
                      <p>
                        applied filter 1 <span>X</span>
                      </p>
                    </div>
                    <div className="fl-clear-all">
                      <p>Clear all</p>
                    </div>
                  </div>
                  <div className="fl-clears">
                    <div className="fl-short-by">
                      <h6>
                        Sort by: <span>Low to High</span>{" "}
                        <i className="fas fa-caret-down"></i>
                      </h6>
                    </div>
                    <div className="fl-grid-view">
                      <Link to="/">
                        <img src="/images/grid.png" alt="grid" />
                      </Link>
                      <Link to="/">
                        <img src="/images/menu-3.png" alt="menu" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agencies-registred-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="gencies-registred-warp">
                <div className="container">
                  <div className="row">
                    <AgencyCard
                      name={"Company XPTO"}
                      services={"muti-services"}
                      posts={56}
                      type={1}
                    />
                    <AgencyCard
                      name={"Company XPTO"}
                      services={"muti-services"}
                      posts={56}
                      type={1}
                    />
                    <AgencyCard
                      name={"Company XPTO"}
                      services={"muti-services"}
                      posts={56}
                      type={1}
                    />
                    <AgencyCard
                      name={"Company XPTO"}
                      services={"muti-services"}
                      posts={56}
                      type={1}
                    />
                    <AgencyCard
                      name={"Company XPTO"}
                      services={"muti-services"}
                      posts={56}
                      type={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agencies;
