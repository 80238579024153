import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function Footer() {
  return (
    <div className="footer">
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footer-logo">
                <h1>APP LOGO</h1>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="footer-link">
                <h5>COMPANY</h5>
                <Link to="/">About</Link>
                <Link to="/">Careers</Link>
                <Link to="/">Contact Us </Link>
                <Link to="/">Blog</Link>
                <Link to="/">Terms & Privacy</Link>
                <Link to="/">Publication Policies</Link>
              </div>
            </div>
            <div className="col-lg-2  col-md-3  col-sm-6">
              <div className="footer-link">
                <h5>EXPLORE</h5>
                <Link to="/">New Cars</Link>
                <Link to="/">Used Cars </Link>
                <Link to="/">Buy Houses </Link>
              </div>
            </div>
            <div className="col-lg-2  col-md-3  col-sm-6">
              <div className="footer-link">
                <h5>CONNECT US</h5>
                <Link to="/">Twitter</Link>
                <Link to="/">Facebook</Link>
                <Link to="/">Instagram</Link>
                <Link to="/">Youtube</Link>
                <Link to="/">Newsletter</Link>
              </div>
            </div>
            <div className="col-lg-2  col-md-3  col-sm-6">
              <div className="footer-link">
                <h5>MOBILE</h5>
                <Link to="/">Android Badge</Link>
                <Link to="/">IOS Badge</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="copy-right">
                <p>
                  &copy; 2020 MARKETSTDB App Developed by{" "}
                  <a href="https://sarifostech.com">STS</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="bottom-right" hideProgressBar={true} />
      </footer>
    </div>
  );
}

export default Footer;
