import React from "react";
import { Link } from "react-router-dom";

function AgencyCard({ name, services, posts, type }) {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="agencies-item">
        <div className="agencies-logo">
          <Link to="/">
            <img src="/images/company-loog-1.png" alt="" />
          </Link>
        </div>
        <div className="agencies-content">
          <h3>
            <Link to="/">{name}</Link>
          </h3>
          <div className="po-bed-items">
            <div className="po-bed-item">
              <div className="po-bedicon">
                <img src="/images/what.png" alt="what" />
              </div>
              <div className="po-bedi-cont">
                <h6>Services</h6>
                <p>{services}</p>
              </div>
            </div>
            <div className="po-bed-item">
              <div className="po-bedicon">
                <img src="/images/what.png" alt="what" />
              </div>
              <div className="po-bedi-cont">
                <h6>{type === 1 ? "Properties" : "Cars"}</h6>
                <p>{posts}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgencyCard;
