import axios from "axios";
import { API_URL } from "../Constants";
//import authHeader from "./auth-header";

class LocationService {
  getAllCities() {
    return axios.get(`${API_URL}cities?size=999999`);
  }
}

export default new LocationService();
