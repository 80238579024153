import React, { useEffect, useState } from "react";
import "./Navbar.css";
import CartIcon from "@material-ui/icons/ShoppingCart";
import TopBanner from "./TopBanner";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { default as UUID } from "node-uuid";
import AuthService from "../services/AuthService";
import { Offline } from "react-detect-offline";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import StoreIcon from "@material-ui/icons/Store";
import CartPreview from "./CartPreview";
import ProductService from "../services/ProductService";

function Navbar({ isAuth, setIsAuth }) {
  const collapse = () => {
    document.getElementById("hamburguer").classList.toggle("change");
  };

  /*
   * TOP Banner - disabled for now
   */
  const [showBanner, setShowBanner] = React.useState(false);
  const onClick = () => setShowBanner(false);
  const { t } = useTranslation();
  const [currLocation, setCurrLocation] = useState(window.location.pathname);
  const history = useHistory();

  useEffect(() => {
    /* setCurrLocation(window.location.pathname); */
    return history.listen((location) => {
      setCurrLocation(location.pathname);
    });
  }, [history]);

  const [totalCartItems, setTotalCartItems] = useState(0);
  const [totalFavorites, setTotalFavorites] = useState(0);

  useEffect(() => {
    const getTotalCartItems = () => {
      ProductService.getCartCount().then((result) => {
        setTotalCartItems(result.data);
      });
    };
    const getTotalFavoriteItems = () => {
      ProductService.getFavoriteCount().then((result) => {
        setTotalFavorites(result.data);
      });
    };
    const loggedUser = localStorage.getItem("user");
    if (loggedUser) {
      getTotalCartItems();
      getTotalFavoriteItems();
    }
  }, []);

  const [id1, setId1] = useState();
  const [id2, setId2] = useState();
  useEffect(() => {
    setId1(UUID.v4());
    setId2(UUID.v4());
  }, []);

  const catOne = {
    pathname: "/products/1",
    key: id1,
  };

  const catTwo = {
    pathname: "/products/2",
    key: id2,
  };

  return (
    <div className="header">
      <div className="header-area">
        <Offline>
          <TopBanner
            showClose={false}
            message="Ups, looks like you are offline. Please check your connection"
            bgColor="#B53737"
            color="#FFF"
          />
        </Offline>

        {showBanner ? (
          <TopBanner
            onClickHandle={onClick}
            showClose={true}
            bgColor="#0033a1"
            color="#FFF"
            message="<span>50% SALE </span>Laudem et iusto odio dignissimos ducimus, qui
          dolorem eum fugiat, quo ignorare vos."
          />
        ) : null}

        <div className="header-content">
          <div className="container-area">
            <div className="logo">
              <Link to="/">Logo</Link>
            </div>
            <div className="menu-area">
              <nav className="main-menu">
                <ul id="navigation">
                  <li>
                    <Link
                      className={currLocation === "/products/2" ? "active" : ""}
                      to={catTwo}
                    >
                      {t("navbar.cars")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={catOne}
                      className={currLocation === "/products/1" ? "active" : ""}
                    >
                      {t("navbar.houses")}
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="src--sin-jo">
              <div className="pc-none mobile-src">
                <img src="/images/search-black.png" alt="search" />
              </div>
              <div className="menu-src-bar">
                <form action="">
                  <input
                    type="text"
                    placeholder={t("navbar.search_bar_placeholder")}
                  />
                  <button className="src-icon">
                    <img src="/images/search-black.png" alt="search" />
                  </button>
                </form>
              </div>
              <div className="card-sin-jo">
                <div className="card-menu-items">
                  <Link className="add-to-card" to="/cart">
                    <CartIcon />
                    {totalCartItems > 0 ? <span>{totalCartItems}</span> : ""}
                  </Link>
                  <CartPreview totalCartItems={totalCartItems} />
                </div>

                <Link className="add-to-card" to="/favorites">
                  <FavoriteIcon />
                  {totalFavorites > 0 ? <span>{totalFavorites}</span> : ""}
                </Link>

                {isAuth ? (
                  <Link
                    to="/"
                    onClick={() => {
                      AuthService.logout();
                      setIsAuth(false);
                    }}
                  >
                    <p className="bg-0033a1 mo-none">{t("navbar.signOut")}</p>
                  </Link>
                ) : (
                  <Link to="/login">
                    <p className="bg-0033a1 mo-none">{t("navbar.signIn")}</p>
                  </Link>
                )}

                {isAuth ? (
                  ""
                ) : (
                  <div className="joinus-sub-menu">
                    <Link className="joinus mo-none" to="#">
                      {t("navbar.join")}
                    </Link>

                    <ul className="join-sub">
                      <li className="active">
                        <Link to="/register">
                          <PersonAddIcon /> Join as User
                        </Link>
                      </li>
                      <li>
                        <Link to="/register/dealer">
                          <StoreIcon /> Join as Dealer
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}

                {/* <ul className="lang-items">
                  <li>
                    EN <i className="fas fa-caret-down"></i>
                    <ul className="lang-sub">
                      <li className="active">
                        <a href="/">
                          <img src="/images/EN.png" alt="" /> EN{" "}
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <img src="/images/mz-big.jpg" alt="" /> PT{" "}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul> */}
              </div>
            </div>
            <div id="hamburguer" className="mobile-menu" onClick={collapse}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          </div>
          <div className="mobile-sign-item pc-none">
            {/* <Link to="/" className="bg-0033a1">
              {t("navbar.signIn")}
            </Link> */}
            {isAuth ? (
              <Link to="/logout">
                <p className="bg-0033a1 mo-none">{t("navbar.signOut")}</p>
              </Link>
            ) : (
              <Link to="/login">
                <p className="bg-0033a1 mo-none">{t("navbar.signIn")}</p>
              </Link>
            )}
            <Link className="joinus" to="/register">
              {t("navbar.join")}
            </Link>
            {/* <ul className="lang-items">
              <li>
                EN <i className="fas fa-caret-down"></i>
                <ul className="lang-sub">
                  <li className="active">
                    <a href="/">
                      <img src="assets/image/EN.png" alt="" /> EN{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <img src="assets/image/mz-big.jpg" alt="" /> PT{" "}
                    </a>
                  </li>
                </ul>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
