import { Fade } from "react-slideshow-image";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HomeSlide from "./home_components/HomeSlide";
import "react-slideshow-image/dist/styles.css";
import ProductCard from "./product/ProductCard";
import DoubleCardLeft from "./home_components/DoubleCardLeft";
import DoubleCardRigth from "./home_components/DoubleCardRight";
import ProductService from "../services/ProductService";
import { API_URL } from "../Constants";

function Home() {
  const [products, setProducts] = useState([]);

  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
  };

  useEffect(() => {
    const getProducts = () => {
      ProductService.getLandingPageProducts().then((result) => {
        setProducts(result.data);
      });
    };
    getProducts();
  }, []);

  const slideContent = [
    {
      title: "Your Dream Houser for Less 1",
      description:
        "Enjoy attractive fixed or floating rates when you apply online for a home loan",
      buttonTitle: "Learn More",
      buttonLink: "/",
      image: "/images/0-landing-page/002.jpg",
    },
    {
      title: "Your Dream Houser for Less 2",
      description:
        "Enjoy attractive fixed or floating rates when you apply online for a home loan",
      buttonTitle: "Learn More",
      buttonLink: "/",
      image: "/images/0-landing-page/002.jpg",
    },
    {
      title: "Your Dream Houser for Less 3",
      description:
        "Enjoy attractive fixed or floating rates when you apply online for a home loan",
      buttonTitle: "Learn More",
      buttonLink: "/",
      image: "/images/0-landing-page/002.jpg",
    },
    {
      title: "Your Dream Houser for Less 4",
      description:
        "Enjoy attractive fixed or floating rates when you apply online for a home loan",
      buttonTitle: "Learn More",
      buttonLink: "/",
      image: "/images/0-landing-page/002.jpg",
    },
  ];

  return (
    <div>
      <div className="landing-page-slider-area">
        <div className="landing-page-slider-items slider-area">
          <Fade {...properties}>
            {slideContent.map((v, k) => {
              return <HomeSlide {...v} key={k} />;
            })}
          </Fade>
        </div>
      </div>
      <div className="visit-our-mar-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="visit-our-mar-heading">
                <h3 className="allheading-con">Visit our marketplace! </h3>
                <p>
                  In quo pertineant non quo quaerimus, non numquam eius modi
                  tempora incidunt, ut summo bono, dolorem ipsum autem nusquam
                  hoc epicurus in culpa, qui in ea quid bonum esse appetendum,
                  alterum aspernandum sentiamus alii autem, quibus ego
                  assentior, cum.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="visit-our-mar-item"
                style={{ background: "url(/images/0-landing-page/001.jpg)" }}
              >
                <div className="visit-our-mar-cont">
                  <h4>Cars</h4>
                  <p>
                    Buy a used or brand new car. Pay with best plans on your
                    time.
                  </p>
                  <Link to="/products/2" className="theme-btn bg-00a1e0">
                    Explore
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="visit-our-mar-item"
                style={{
                  background:
                    "url(/images/0-landing-page/greg-rivers-rChFUMwAe7E-unsplash.jpg)",
                }}
              >
                <div className="visit-our-mar-cont">
                  <h4>Houses</h4>
                  <p>
                    Buy a used or brand new car. Pay with best plans on your
                    time.
                  </p>
                  <Link to="/products/1" className="theme-btn bg-00a1e0">
                    Explore
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="landing-houses-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="allheading-con">Last Products Posted</h3>
            </div>

            {products.map((v, k) => {
              return (
                <ProductCard
                  key={k}
                  title={v.title}
                  productId={v.id}
                  location={"Maputo"}
                  price={v.price}
                  age={v.publishDate}
                  url={`${API_URL}get-product-thumb/${v.id}/${v.id}.png`}
                  photos={4}
                />
              );
            })}

            <div className="col-12">
              <div className="more-post">
                <button className="more-post-all bg-0AA0E4">
                  See all Properties
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="want-sell-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="want-sell-cont-area">
                <div className="want-sell-cont">
                  <h3>Want Sell you Products?</h3>
                  <p>Check our solutions for agencies and dealers.</p>
                </div>
                <div className="want-sell-btn">
                  <Link to="/" className="theme-btn bg-0AA0E4">
                    SWITCH TO BUSINESS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="next-move-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="allheading-con">
                Why you next move should be with us?
              </h3>
              <div className="next-move-items">
                <div className="next-move-item">
                  <div className="next-move-top bg-0033a1">
                    <img src="/images/0-landing-page/Agile.png" alt="" />
                  </div>
                  <div className="next-move-cont">
                    <h4>Flexibility</h4>
                    <p>
                      Access funds on the credit or lending terms you prefer
                    </p>
                  </div>
                </div>
                <div className="next-move-item bg-grd">
                  <div className="next-move-top">
                    <img src="/images/0-landing-page/Relax.svg" alt="" />
                  </div>
                  <div className="next-move-cont">
                    <h4>Make life easy</h4>
                    <p>
                      Manage your assets and payments wherever you are, whenever
                      you like, on any device
                    </p>
                  </div>
                </div>
                <div className="next-move-item">
                  <div className="next-move-top bg-0033a1">
                    <img src="/images/0-landing-page/Trusted.png" alt="" />
                  </div>
                  <div className="next-move-cont">
                    <h4>Can be trusted</h4>
                    <p>
                      The solutions we innovate for your convenience are
                      supported by world-class security
                    </p>
                  </div>
                </div>
                <div className="next-move-item bg-grd">
                  <div className="next-move-top">
                    <img src="/images/0-landing-page/Award.svg" alt="" />
                  </div>
                  <div className="next-move-cont">
                    <h4>Best of Best</h4>
                    <p>
                      Our marketplace gives you easy, anytime access to the best
                      dealers and products in the country
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="marketplace-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="marketplace-cont">
                <h4>Open MarketPlace Account</h4>
                <p>Join us now by following a few easy online steps</p>
                <Link to="/" className="theme-btn bg-fff">
                  CREATE ACCOUNT NOW
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tips-information-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="allheading-con">Tips & Informations</h3>
            </div>
            <DoubleCardLeft
              titleLeft="Attractive Rates"
              descriptionLeft="Enjoy attractive fixed or floating rates when you apply online for
              a home loan."
              backgroundLeft="url(/images/0-landing-page/SPOTLIGHT-003-CASE-L.jpg)"
              linkLeft="/"
              linkLeftTile="Learn more"
              titleRight="Need a home loan? Get pre-approved"
              descriptionRight="We can offer competitive mortgage rates and help you with
              pre-approval."
              backgroundRight="tips-information-item bg-00a1e0"
              linkRight="/"
              linkRightTitle="Learn more"
            />
            <DoubleCardRigth />
            <DoubleCardLeft
              titleLeft="Plan for your future home"
              descriptionLeft="Cut the guesswork. Let us help you work out the sums and
              find a home that meets your budget and preferences."
              backgroundLeft="url(/images/0-landing-page/florian-schmidinger-b_79nOqf95I-unsplash.jpg)"
              linkLeft="/"
              linkLeftTile="Learn more"
              titleRight="Mortgage Rates"
              descriptionRight="Get personalized rate quotes based on your home loan
              criteria."
              backgroundRight="tips-information-item bg-00a860"
              linkRight="/"
              linkRightTitle="Learn more"
            />
          </div>
        </div>
      </div>
      <div className="subscribe-newsletter-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="subscribe-newsletter-cont">
                <h4>Subscribe to our newsletter</h4>
                <p>
                  Sign up for the newsletter to receive the latest
                  communications on exciting new products delivered right to
                  your inbox
                </p>
                <Link to="/">SUBSCRIBE</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
