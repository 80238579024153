import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "../Constants";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getUserBoard() {
    return axios.get(API_URL + "user", { headers: authHeader() });
  }

  getAccount() {
    return axios.get(API_URL + "account", { headers: authHeader() });
  }

  getCurrentUserData() {
    return JSON.parse(localStorage.getItem("userData"));
  }

  createAccount(user) {
    return axios.post(API_URL + "register", user);
  }

  sellerRegister(user) {
    return axios.post(API_URL + "seller-register", user);
  }

  createCompany(company) {
    return axios.post(API_URL + "create-company", company);
  }
}

export default new UserService();
