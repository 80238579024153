import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AgentContactCard from "./AgentContactCard";
import CategoryOne from "./CategoryOne";
import CategoryThree from "./CategoryThree";
import PaymentSimulation from "./PaymentSimulation";
import SimilarProducts from "./SimilarProducts";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import ProductService from "../../services/ProductService";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AuthService from "../../services/AuthService";
import { toast } from "react-toastify";
import SpecItems from "./SpecItems";

function ProductDetail() {
  const catThree = false;
  let slug = useParams();

  const descRef = useRef(null);
  const paymentsRef = useRef(null);

  const executeScroll = (elementRef) => {
    if (elementRef) {
      elementRef.current.scrollIntoView();
    }
  };

  const [product, setProduct] = useState({});
  const [wishes, setWishes] = useState();
  const [wishesId, setWishesId] = useState();
  const [isAuth, setIsAuth] = useState();
  const [productFields, setProductFields] = useState([]);

  useEffect(() => {
    const checkIsAuth = () => {
      AuthService.isAuthenticated().then((result) => {
        if (result && result.data) {
          setIsAuth(true);
        }
      });
    };
    checkIsAuth();
  }, []);

  useEffect(() => {
    const getProductDetails = async () => {
      ProductService.getOne(slug.id).then((response) => {
        setProduct(response.data);
        ProductService.getProductFields(slug.id).then((result) => {
          setProductFields(result.data);
        });
      });
    };
    getProductDetails();
  }, [slug.id]);

  useEffect(() => {
    const isInWishlist = () => {
      AuthService.isAuthenticated().then((result) => {
        if (result.data !== undefined && product.id) {
          ProductService.getFavorite(product.id).then((result) => {
            setWishes(result.data && result.data.id);
            if (result.data) {
              setWishesId(result.data.id);
            }
          });
        }
      });
    };
    isInWishlist();
  }, [product.id]);

  const addRemoveWishlist = (id) => {
    AuthService.isAuthenticated().then((result) => {
      if (result.data !== undefined) {
        if (wishes) {
          //console.log(wishesId);
          ProductService.removeFavorite(wishesId).then((result) => {
            console.log(result);
            if (result.status === 200) {
              toast.warning("Item removido aos favoritos");
              if (product.id) {
                ProductService.getFavorite(product.id).then((result) => {
                  setWishes(result.data && result.data.id);
                });
              }
            }
          });
        } else {
          ProductService.addToFavorite(id).then((result) => {
            if (result.data && result.data.id) {
              toast.success("Item adicionado aos favoritos");
              if (product.id) {
                ProductService.getFavorite(product.id).then((result) => {
                  setWishes(result.data && result.data.id);
                  setWishesId(result.data.id);
                });
              }
            }
          });
        }
      } else {
        toast.warning(
          "Tem que ter uma conta para adicionar aos favoritos, pode Registar uma ou efectuar o Login se já tiver registado."
        );
      }
    });
  };

  return (
    <div style={{ backgroundColor: "#f4f4f4", paddingBottom: "50px" }}>
      <div className="view-page-link">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="back-menu">
                <ul>
                  <li className="back-page">
                    <Link to="/">
                      <ChevronLeftIcon />
                      Back
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <ChevronRightIcon />{" "}
                      {product.subCategory && product.subCategory.category
                        ? product.subCategory.category.name
                        : ""}
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <ChevronRightIcon />{" "}
                      {product.subCategory ? product.subCategory.name : ""}
                    </Link>
                  </li>
                  <li>
                    <ChevronRightIcon /> {product.title}
                  </li>
                </ul>

                {catThree ? (
                  <div>
                    <h2>{product.title}</h2>
                    <h5>
                      {/* <img
                    src="/images/productView/landRover.svg"
                    alt="landRover"
                  /> */}
                      <EmojiTransportationIcon /> &nbsp; {product.description}
                    </h5>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {catThree ? <CategoryThree /> : ""}

      <div className="left-right-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="left-area">
                {!catThree ? (
                  <CategoryOne
                    wishes={wishes}
                    addRemoveWishlist={addRemoveWishlist}
                    productId={product.id}
                    title={product.title}
                    city={product.city ? product.city.name : ""}
                    publishDate={product.publishDate}
                    price={product.price}
                  />
                ) : (
                  ""
                )}

                <div className="description-tab-area">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <Link
                        to="#"
                        className="nav-link active"
                        id="specifications-tab"
                        data-toggle="tab"
                        href="#specifications"
                        role="tab"
                        aria-controls="specifications"
                        aria-selected="true"
                      >
                        SPECIFICATIONS
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className="nav-link"
                        id="description-tab"
                        data-toggle="tab"
                        onClick={() => executeScroll(descRef)}
                        role="tab"
                        aria-controls="description"
                        aria-selected="false"
                      >
                        DESCRIPTION
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className="nav-link"
                        id="payments-tab"
                        data-toggle="tab"
                        onClick={() => executeScroll(paymentsRef)}
                        role="tab"
                        aria-controls="payments"
                        aria-selected="false"
                      >
                        MONTHLY PAYMENTS
                      </Link>
                    </li>
                  </ul>

                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="specifications"
                      role="tabpanel"
                      aria-labelledby="specifications-tab"
                    >
                      <div className="specifications-bg">
                        <SpecItems product={product} />

                        <div className="engineDetails">
                          <h5 className="engineDetails-headign" ref={descRef}>
                            Product Details
                          </h5>
                          <div className="engineDetails-items">
                            {/* <div className="engineDetails-item">
                              <div className="engineDetails-item-left">
                                <h5>Make</h5>
                              </div>
                              <div className="engineDetails-item-right">
                                <img
                                  src="/images/productView/landRover.svg"
                                  alt=""
                                />
                                <p>Range Rover</p>
                              </div>
                            </div> */}

                            {product.subCategory ? (
                              <div className="engineDetails-item">
                                <div className="engineDetails-item-left">
                                  <h5>Categoria</h5>
                                </div>
                                <div className="engineDetails-item-right">
                                  <p>{product.subCategory.name}</p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {productFields.map((value, key) => {
                              if (value.field.type === "TEXT") {
                                return (
                                  <div className="engineDetails-item" key={key}>
                                    <div className="engineDetails-item-left">
                                      <h5>{value.field.name}</h5>
                                    </div>
                                    <div className="engineDetails-item-right">
                                      <p>{value.value}</p>
                                    </div>
                                  </div>
                                );
                              } else {
                                return "";
                              }
                            })}

                            {/* <div className="engineDetails-item">
                              <div className="engineDetails-item-left">
                                <h5>Available colors</h5>
                              </div>
                              <div className="engineDetails-item-right">
                                <div className="color-2d">
                                  <div className="color-2d-tw">
                                    <p>Interior</p>
                                    <span className="bg-bd5252"></span>
                                    <span className="bg-e3e3e3"></span>
                                    <span className="bg-000000"></span>
                                  </div>
                                  <div className="color-2d-tw">
                                    <p>Exterior</p>
                                    <span className="bg-0b50e6"></span>
                                    <span className="bg-ffffff"></span>
                                    <span className="bg-011623"></span>
                                    <span className="bg-051625"></span>
                                    <span className="bg-4f747a"></span>
                                    <span className="bg-e3e3e3"></span>
                                    <span className="bg-000000"></span>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>

                        <div className="primum-igitur" ref={paymentsRef}>
                          <h4>Description</h4>
                          <h5>Product Description</h5>
                          <p>{product.description}</p>
                          <br></br>
                        </div>
                      </div>
                      <PaymentSimulation
                        price={product.price}
                        product={product}
                        category={
                          product.subCategory
                            ? product.subCategory.category
                              ? product.subCategory.category.id
                              : 0
                            : 0
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AgentContactCard
              name={product.company ? product.company.companyName : ""}
              website={product.company ? product.company.website : ""}
              isAuth={isAuth}
            />
          </div>
        </div>
      </div>
      <SimilarProducts />
    </div>
  );
}

export default ProductDetail;
